import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom';
import {connect} from "react-redux";

// 资源
import loadicon from "../resource/loading.png";

// 自定义组件
import IndexItem from "./indexitem";
import {apis} from "../api";
import {setProvider} from "../../redux/action";



function Provider(props){

  // State
  const [loaded, setloaded] = useState(false)

	// Route
	const paras = useParams()
	const providerid = paras.id

	useEffect(()=>{
		// 应用详情
		apis('getprovider', {provider:providerid}).then(
			(res) => {
				// console.log("get favoritecheck response:", res);
				props.setProvider(res)
				setloaded(true)
			}).catch(
			(error) => {
				console.log("getprovider request failed!", error);
			}
		);
	}, [providerid])

	if(!loaded){
    return(
      <div style={{width:'100%', textAlign:"center"}}><img src={loadicon} className='rotateImg indexloading' style={{height:'32px', width:'32px', margin:'10px auto'}} alt=''/></div>
    )
  }

  return (
    <div className='contain'>
	
	    {props.provider.logooss &&
		    <div>
			    <img src={props.provider.backgroundoss} className='creatorback' alt=''/>
				  <div className='creatoruser'>
					  <div className='creatoruserdiv'>
						  <img src={props.provider.logooss} className='creatoruseravatar' alt=''/>
							<div className='creatorusername'>{props.provider.name}</div>
					  </div>
					  <div className='creatoruserdes'>{props.provider.description}</div>
				  </div>
		    </div>
			}

	    
      <div style={{overflow:"auto", margin:'10px'}}>
        <div style={{fontSize:'20px', fontWeight:"bold", lineHeight:'32px', color:'#3d3d3d', float:"left"}}>{props.provider.name}的产品</div>
      </div>
	    
	    <div className='indexitemset'>
        {
          props.provider.appprovider.map((value, index)=>(
            <IndexItem data={value} key={index}/>
          ))}
      </div>

    </div>

  )
}

// export default Provider;

const mapStateToProps = store => ({
	provider: store.app.provider,
});

const mapDispatchToProps = dispatch => ({
	setProvider: (provider) => dispatch(setProvider(provider))
});

export default connect(mapStateToProps, mapDispatchToProps)(Provider);