import React, {useEffect, useState} from 'react'

// 资源
import loadicon from "../resource/loading.png";

// 自定义组件
import IndexItem from "./indexitem"
import {apis} from "../api";


function Favorite(){

  // State
  const [loaded, setloaded] = useState(false)
  const [favorite, setfavorite] = useState([])

  useEffect(()=>{
    // 收藏列表
    apis('getfavorite').then(
      (res) => {
        // console.log("get favoritelist response:", res);
        setfavorite(res)
        setloaded(true)
      },
      (error) => {
        console.log("getfavorite request failed!", error);
      }
    );

  }, [])
  
  if(!loaded){
    return(
      <div style={{width:'100%', textAlign:"center"}}><img src={loadicon} className='rotateImg indexloading' style={{height:'32px', width:'32px', margin:'10px auto'}} alt=''/></div>
    )
  }

  return (
    <div className='indexitemset'>
    {
      favorite.map((value, index)=>(
        <IndexItem data={value.appid} key={index}/>))
    }
    </div>
  )
}


export default Favorite;