import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import {Link} from "react-router-dom";

// 自定义组件
import {chunk} from "../../Utiles/utiles";
import {apis} from "../api";
import {setIndexRank, setIndexTopics, setUserinfo} from "../../redux/action";
import IndexItem2 from "./indexitem2";
import IndexItem from "./indexitem";


function Index(props){
	
	// State
	const [rankname, setrankname] = useState('score')
	const [currentrank, setcurrentrank] = useState([])
	
	const switchRank = (rank) => {
		setrankname(rank)
		setcurrentrank(chunk(props.indexRank[rank], 3))
	}
	
	useEffect(()=>{
		
		apis('getindexrank').then(
			(res) => {
				// console.log("get mostscore response:", res);
				props.setIndexRank(res)
				setcurrentrank(chunk(res.score, 3))
			}).catch(
			(error) => {
				console.log("getindexrank request failed!", error);
			}
		);
		
		apis('getindextopics').then(
			(res) => {
				// console.log("get mostdownload response:", res);
				props.setIndexTopics(res)
			}).catch(
			(error) => {
				console.log("gettopics request failed!", error);
			}
		);
		
	}, [])
	
	return(
		<div className='indexmain'>
			
			{/*<div className='indexboard'>*/}
			{/*  <div className='indexboarddiv'>*/}
			{/*    <div className='indexboardback'/>*/}
			{/*    <img className='indexboardimg' src={star2} alt=''/>*/}
			{/*  </div>*/}
			{/*  <i className='material-icons indexboardleft'>keyboard_arrow_left</i>*/}
			{/*  <i className='material-icons indexboardright'>keyboard_arrow_right</i>*/}
			{/*  <div className='indexboardtitle'>软件小标题</div>*/}
			{/*  <div className='indexboardbtn'>查 看</div>*/}
			{/*</div>*/}
			
			<div className='indexrank'>
				<div className='indexrankdiv'>
					<div className='indexranktitle'>热门排行榜</div>
					<div className='indexranktitles'>
						<div className={rankname==='score' ? 'indexranksubtitle1' : 'indexranksubtitle2'} onClick={()=>{switchRank('score')}}>最高评分</div>
						<div className={rankname==='download' ? 'indexranksubtitle1' : 'indexranksubtitle2'} onClick={()=>{switchRank('download')}}>最多下载</div>
						<div className={rankname==='recent' ? 'indexranksubtitle1' : 'indexranksubtitle2'} onClick={()=>{switchRank('recent')}}>最近更新</div>
					</div>
				</div>
				
				<div className='indexranktable'>
					{currentrank.map((group, index)=>(
						<div className='indexrankgroup' style={{width:'33.33%', minWidth:'240px'}} key={index}>
							{group.map((data, index2)=>(
								<IndexItem2 data={data} num={index*3+index2+1} index={true} key={index2}/>
								))
							}
						</div>
					))}
				</div>
				
			</div>
			
			<div className='indexcontent'>
				{
					Object.keys(props.indexTopics).map((topic, index)=>(
						<div key={index}>
				      <div className='indextitle'>
				        <div className='indextitletext'>{topic}</div>
				        <Link to={"/topic/" +topic + '/'} style={{textDecoration:"none"}}>
					        <div className='indextitlebtn'>查看更多</div>
								</Link>
				      </div>
				
				      <div className='indexitemset'>
				        {
				          props.indexTopics[topic].map((value, index)=>(
				            <IndexItem data={value} key={index}/>
				          ))}
				      </div>
				    </div>
					))
				}
			</div>
		</div>
	
	)
}


// export default Index;

const mapStateToProps = store => ({
	userinfo: store.app.userinfo,
	indexRank: store.app.indexRank,
	indexTopics: store.app.indexTopics
});

const mapDispatchToProps = dispatch => ({
	setUserinfo: (userinfo) => dispatch(setUserinfo(userinfo)),
	setIndexRank: (rank) => dispatch(setIndexRank(rank)),
	setIndexTopics: (topics) => dispatch(setIndexTopics(topics)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);