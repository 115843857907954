import React, {useRef, useState} from 'react'
import {Link, useNavigate} from "react-router-dom";

import {register} from "./api";
import logo from "./static/logo.png";
import {SuccessPopup, UnknownFailPopup} from "./popups"


function Register(){
  // Ref(input的非受控组件方法)
  const nameRef = useRef('')
  const emailRef = useRef('')
  const passwdRef = useRef('')
  const passwd2Ref = useRef('')

  // State
  // 用户名状态
  const [namestate, setnamestate] = useState('initial')
  const [nameerror, setnameerror] = useState('')
  // 邮件状态
  const [emailstate, setemailstate] = useState('initial')
  const [emailerror, setemailerror] = useState('')
  // 密码状态
  const [passwdstate, setpasswdstate] = useState('initial')
  const [passwderror, setpasswderror] = useState('')
  // 密码确认
  const [passwd2state, setpasswd2state] = useState('initial')

  // 是否显示密码
  const [showpasswd, setshowpasswd] = useState(false)
  
  // 显示注册成功
  const [success, setsuccess] = useState(false)
  
  // 未知错误弹窗
  const [unknown, setunknown] = useState('')


  // react-route
  const navigate = useNavigate()


  // 显示密码
  const handleChePasswd = (e) => {
    // console.log(e.target.checked)
    setshowpasswd(e.target.checked)
  }

  // 处理点击搜索
  const handleNext = () => {
    const username = nameRef.current.value
    const email = emailRef.current.value
    const passwd = passwdRef.current.value
    const passwd2 = passwd2Ref.current.value
    // 用户名本地验证
    const chename = checkName(username)
    // 用户名服务器验证
    if(chename){} else {
      return
    }


    // 邮件本地验证
    const cheemail = checkEmail(email)
    // 邮件服务器验证
    if(cheemail){} else {
      return
    }


    // 密码本地验证
    const chepasswd = checkPasswd(passwd, passwd2)

    // 注册请求
    if(chepasswd){
      register(username, email, passwd)
      .then((lol)=>{
        if(lol.state === false){
          if(lol.detail === '该邮件已注册'){
            setemailstate('error')
            setemailerror('occupy')
          } else if(lol.detail === '该用户名已占用'){
            setnamestate('error')
            setnameerror('occupy')
          } else {
            setunknown(lol.detail)
          }
        } else {
          // 注册成功,显示注册成功弹窗
          setsuccess(true)
        }
        
      })
      .catch((error)=>{
        // 请求的错误由请求函数处理
        console.log('注册错误处理：', error)
      })
    }

  }

  // 解析用户名，英文字符数字占1位，中文字符占2位
  const getNameLength = (str) => {
    if (typeof str != "string") {
      str += "";
    }
    return str.replace(/[^\x00-\xff]/g, "01").length;
  }


  // 检测用户名合法
  const checkName = (name) => {
    // 中文字符、英文字符、数字
    const pattern = /[\u4E00-\u9FA5A-Za-z0-9_]+$/
    // 用户名为空
    if (name===''){
      setnamestate('error')
      setnameerror('empty')
      return false;
    }
    // 用户名长度(4-10)
    const namelength = getNameLength(name)
    // console.log(namelength, '用户名长度')
    if (4>namelength || namelength>12){
      setnamestate('error')
      setnameerror('invalid1')
      return false;
    }
    // 用户名字符
    if (!pattern.test(name)){
      setnamestate('error')
      setnameerror('invalid2')
      return false;
    }
    return true
  }
  // 检测邮件合法
  const checkEmail = (email) => {
    const pattern = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
    // 邮件为空
    if (email===''){
      setemailstate('error')
      setemailerror('empty')
      return false;
    }
    // 邮件长度(邮件名称最长64，域名部分最长255)
    const emailname = email.split('@')
    if (emailname.length>64){
      setemailstate('error')
      setemailerror('invalid')
      return false;
    }
    // 邮件字符
    if (!pattern.test(email)){
      setemailstate('error')
      setemailerror('invalid')
      return false;
    }
    // 邮件后缀
    return true
  }
  // 检测密码合法
  const checkPasswd = (passwd, passwd2) => {
    const pattern = /^[a-zA-Z0-9-]$/
    // 密码为空
    if (passwd==='' || passwd2 ===''){
      setpasswdstate('error')
      setpasswd2state('error')
      setpasswderror('empty')
      return false;
    }
    // 密码匹配
    if (passwd!==passwd2){
      console.log(passwd, passwd2)
      setpasswdstate('error')
      setpasswd2state('error')
      setpasswderror('unmatched')
      return false;
    }
    // 密码长度
    if (8>passwd.length || passwd.length>16){
      setpasswdstate('error')
      setpasswd2state('error')
      setpasswderror('invalid1')
      return false;
    }
    // 密码字符
    if (pattern.test(passwd)){
      setpasswdstate('error')
      setpasswd2state('error')
      setpasswderror('invalid2')
      return false;
    }
    return true;
  }
  
  // 注册成功
  const successPopup = () => {
    navigate('/')
  }

  // 输入框外点击，如果输入框为空，input样式变为initial
  const loseInputFocus = (event) => {
    try{
      // 输入框为空，输入框外部点击使输入状态重置为initial
      if(nameRef.current.value === "" && !nameRef.current.contains(event.target)){
        setnamestate('initial')
      }
      // 输入框为空，输入框外部点击使输入状态重置为initial
      if(emailRef.current.value === "" && !emailRef.current.contains(event.target)){
        setemailstate('initial')
      }
      // 输入框为空，输入框外部点击使输入状态重置为initial
      if(passwdRef.current.value === "" && !passwdRef.current.contains(event.target)){
        setpasswdstate('initial')
      }
      // 输入框为空，输入框外部点击使输入状态重置为initial
      if(passwd2Ref.current.value === "" && !passwd2Ref.current.contains(event.target)){
        setpasswd2state('initial')
      }
    } catch (error){
      console.log(error)
    }
  }
  // 事件监听
  document.addEventListener('mousedown', loseInputFocus)


  // 复杂情况下的className切换方法 //
  // input样式与输入状态的匹配
  const nameclassNmae = {
    initial: "registernameinitial",
    activate: "registernameactivate",
    error: "registernameerror"
  }
  const nametitleclassName = {
    initial: "registernametitleinitial",
    activate: "registernametitleactivate",
    error: "registernametitleerror"
  }

  // 用户名错误类型
  const nameErrorMap = {
    empty:'请输入用户名',
    occupy:'该昵称已被占用',
    invalid1:'请输入合法用户名（长度要求4-12之间，中文占两个字符）',
    invalid2:'请输入合法用户名（字符要求不能包含特殊字符）'
  }
  // 邮件错误类型
  const emailErrorMap = {
    empty:'请输入邮件地址',
    occupy:'该邮件地址已被占用',
    invalid:'请输入合法邮件地址'
  }
  // 密码错误类型
  const passwdErrorMap = {
    empty:'请输入密码',
    unmatched:'两次输入密码不一致',
    invalid1:'请输入合法密码（长度要求8-20位）',
    invalid2:'请输入合法密码（字符要求英文字母大小写、数字、下划线）',
  }


  return(
    <div>
      {/*<div className='logintitle'>TechFlow</div>*/}
      <img  className='logintitleimag' src={logo} alt=''/>
      <div className='loginlogin'>创建您的TechFlow账号</div>

      <div className={nametitleclassName[namestate]}>用户名</div>
      <input className={nameclassNmae[namestate]} onFocus={()=>{setnamestate('activate')}} placeholder={namestate==='initial' ? '用户名' : ''} ref={nameRef}/>
      <div className='registertipdiv'>
        <div className={namestate==='error' ? 'registererror' : 'registererrorhidden'}>
          <i className='material-icons loginerroricon' onClick={()=>{}}>error_outline</i>
          {nameErrorMap[nameerror]}
        </div>
        <div className={namestate!=='error' ? 'registertip' : 'registertiphidden'}>使用4-12个字符（汉字、英文字母、数字和下划线的组合）</div>
      </div>

      <div className={nametitleclassName[emailstate]}>邮件地址</div>
      <input className={nameclassNmae[emailstate]} onFocus={()=>{setemailstate('activate')}} placeholder={emailstate==='initial' ? '邮件地址' : ''} ref={emailRef}/>
      <div className='registertipdiv'>
        <div className={emailstate==='error' ? 'registererror' : 'registererrorhidden'}>
          <i className='material-icons loginerroricon' onClick={()=>{}}>error_outline</i>
          {emailErrorMap[emailerror]}
        </div>
        <div className={namestate!=='error' ? 'registertip' : 'registertiphidden'}/>
      </div>


      <div>
        <div className={nametitleclassName[passwdstate]}>密码</div>
        <input type={showpasswd ? 'text' : 'password'} className={nameclassNmae[passwdstate]} onFocus={()=>{setpasswdstate('activate')}} placeholder={passwdstate==='initial' ? '密码' : ''} ref={passwdRef}/>
        <div className={nametitleclassName[passwd2state]}>确认</div>
        <input type={showpasswd ? 'text' : 'password'} className={nameclassNmae[passwd2state]} onFocus={()=>{setpasswd2state('activate')}} placeholder={passwd2state==='initial' ? '确认' : ''} ref={passwd2Ref}/>
      </div>
      <div className='registertipdiv'>
        <div className={passwdstate==='error' ? 'registererror' : 'registererrorhidden'}>
          <i className='material-icons loginerroricon' onClick={()=>{}}>error_outline</i>
          {passwdErrorMap[passwderror]}
        </div>
        <div className={namestate!=='error' ? 'registertip' : 'registertiphidden'}>使用8-16个字符（字母、数字和符下划线的组合）</div>
      </div>


      <div className='loginpasswddiv'>
        <input className='loginpasswdche' type='checkbox' onChange={handleChePasswd}/>
        <div className='loginpasswddes'>显示密码</div>
      </div>

      <div>
        <Link to="/login/"><div className='logincreate'>登录现有账号</div></Link>
        <div className='loginnext' onClick={handleNext}>下一步</div>
      </div>
  
      {success && <SuccessPopup close={successPopup}/>}
      {unknown.length !== 0 && <UnknownFailPopup error={unknown} close={()=>{setunknown('')}}/>}
    </div>
  )
}

export default Register;