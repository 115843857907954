import React, {useEffect} from "react"
import {BrowserRouter, Routes, Route} from "react-router-dom"
import {connect} from "react-redux";
import {isMobile} from "react-device-detect";


// action
import {getUserInfo} from "../auth/api";
import {setUserinfo} from "../redux/action";

// 桌面端
import Desktop from './desktop/app';
// 移动端
import Mobile from './mobile/app';

// 用户验证
import Auth from "../auth/app";
import {stringToHslColor} from "../Utiles/utiles";

// 整个页面
function App(props){
  
  // 加载css
  useEffect(() => {
    if (isMobile) {
      import('./mobile/mgoogleplay.css');
    } else {
      import('./desktop/googleplay.css');
    }
  }, []);
  
  
  // 自动登录
  useEffect(()=>{
    console.log('自动登录')
    // // 测试用
    // props.setUserinfo({
    //   username: 'test',
    //   email: '123@gmail.com',
    //   color: stringToHslColor('test', 60, 60),
    //   logged: true
    // })
    getUserInfo().then((res)=>{
      console.log('用户信息', res, res.state)
      if(res.state === true){
        const avatarColor = stringToHslColor(res.data.username, 60, 60)
        console.log('用户信息获取成功', {...res.data, color:avatarColor, logged:true})
        props.setUserinfo({...res.data, color:avatarColor, logged:true})
      } else {
        // 删除cookie的token项
        document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      }
    })
  }, [])
  
  
  return (
    <BrowserRouter>
      {/*{isMobile ? <Mobile/> : <Desktop/>}*/}
      {/*<Auth/>*/}
      <Routes>
        <Route path="/*" element={(
          <>
            {isMobile ? <Mobile/> : <Desktop/>}
            <Auth/>
          </>
        )} />
      </Routes>

    </BrowserRouter>
  )
}

const mapStateToProps = store => ({
	userinfo: store.app.userinfo,
});

const mapDispatchToProps = dispatch => ({
	setUserinfo: (userinfo) => dispatch(setUserinfo(userinfo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
