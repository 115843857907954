import React, {useEffect, useContext, useRef, useState} from "react";
import FunctionContext from "../context";
import {apis} from "../api";
import {formatDatetime} from "../../Utiles/utiles";


// 应用举报弹窗
export function ReportPop(props){
  
  // State
  const [modified, setmodified] = useState(false)
  
  // Ref
  const inputRef = useRef(null)
  
  useEffect(()=>{
    if(inputRef){
      inputRef.current.focus();
    }
  })

  const closeReport = () =>{
    props.close()
  }
  const submitReport = () => {
    // 如果各项都为空, 不做任何反应
    if (inputRef.current.value === ''){
      return
    }
    // post提交
    apis('postreport', {app:props.data.id}, {
      content: inputRef.current.value
    }).then((res)=>{
      props.close()
      props.tip('视频举报已提交')
    })
  }

  return(
    
    <div className='SMDbackground'>
      <div className='SMDcontainer' style={{width:"70%", maxWidth:'400px'}}>
        <div className='SMDtitle'>
          举报应用
          <i className='material-icons SMDtitleclose' onClick={props.close}>close</i>
        </div>
        
        <div className='SMDreportcontent'>
          <div className='SMDreportcontentdiv'>
            <textarea className='SMDreportitemtxt' rows={5} onChange={()=>{setmodified(true)}} ref={inputRef}/>
          </div>
          <div className='SMDreportbtndiv'>
            <div className='SMDreportbtn' onClick={closeReport}>取消</div>
            <div className={modified ? 'SMDreportbtn3' : 'SMDreportbtn2'} onClick={submitReport}>确认</div>
          </div>
        </div>
      </div>
    </div>
  )
}


// 提交请求
export function SubmitRequetst(props){
  // state
  const [submits, setSubmits] = useState([])
  const [values, setValues] = useState({name:'',content:''})
  
  // context
  const functions = useContext(FunctionContext);
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (values.name === '') {
      functions.setuptip(`请填写应用名称`)
      return
    }
    
    // if(values.desc === ''){
    //   functions.setuptip(`请填写标签`)
    //   return
    // }
    
    const requestdata = {...values}
    apis('postrequest', {}, requestdata).then((res) => {
      // 添加到列表第一项
      let submits_data = submits
      submits_data.unshift(res)
      setSubmits([...submits_data])
      functions.setuptip(`应用请求:${values.name} 提交成功`)
    })
  }
  
  const deleteApp = async (id) => {
    apis('deleterequest', {requestid:id}).then((res)=>{
      const filteredArray = submits.filter(obj => obj.id !== id);
      setSubmits([...filteredArray])
      functions.setuptip(`应用请求:${values.name} 删除成功`)
    })
  }

  const getApps = async () => {
    apis('getrequest').then((res)=>{
      setSubmits(res)
    }).catch((error)=>{})
  }

  useEffect(()=>{
    getApps()
  }, [])
  
  return (
    <div className='SMDbackground'>
      <div className='SMDcontainer' style={{width:'80%!important', maxWidth:'800px'}}>
        <div className='SMDtitle'>
          请求管理
          <i className='material-icons SMDtitleclose' onClick={props.close}>close</i>
        </div>
        
        <div className='SMDformdiv'>
          <form className='SMDform' onSubmit={(e) => handleSubmit(e)}>
            <div className='SMDformitem'>
              <label htmlFor="title">应用名称</label>
              <input
                className="SMDinput"
                type="text"
                name="name"
                placeholder="请输入应用名称"
                onChange={(e) => setValues({ ...values, name: e.target.value })}
              />
            </div>
            <div className='SMDformitem'>
              <label htmlFor="label">更多描述</label>
              <input
                className="SMDinput"
                type="text"
                name="content"
                placeholder="请输入应用更多细节（方便查找该应用）, 非必须"
                onChange={(e) => setValues({ ...values, content: e.target.value })}
              />
            </div>
            <div>
              <button type="submit" className='SMDbtnregister'>提交</button>
            </div>
          </form>
        </div>
        
        <div className='SMDusers'>
          <div className='SMDitem' style={{background:"rgba(1, 135, 95, 0.15)"}}>
            <div className='SMDitemtxt' style={{width:"30%"}}>应用名称</div>
            <div className='SMDitemtxt' style={{width:"25%"}}>提交时间</div>
            <div className='SMDitemtxt' style={{width:"30%"}}>当前状态</div>
            <div className='SMDitemtxt' style={{width:"15%"}}>删除</div>
          </div>
          
          {submits.map((request, index)=>(
            <div className='SMDitemdiv' key={index}>
              <div className='SMDitem' key={index}>
                <div className='SMDitemtxt' style={{width:"30%"}}>
                  <div style={{textAlign:"center"}}>{request.name}</div>
                </div>
                <div className='SMDitemtxt' style={{width:"25%"}}>{formatDatetime(request.created)}</div>
                <div className='SMDitemtxt' style={{width:"30%"}}>
                  <div style={{textAlign:"center"}}>{request.status}</div>
                </div>
                <div className='SMDbtn' style={{width:"15%"}} onClick={()=>{deleteApp(request.id)}}>删除</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}