import React, {useState} from 'react'

// 自定义组件
import Header from "./header"

function Contain(props){
	
	return(
		<div className='googleplayindex'>
			<Header/>
			
			<div className='contentdiv'>
				<div className='content'>
					{props.sub}
				</div>
			</div>
		</div>
	)
}


export default Contain;