import React, {useRef, useState} from 'react'
import {Link} from "react-router-dom"; // react-router
import {isMobile} from 'react-device-detect'; // 检测是否是移动端访问

// 自定义组件
import {passwdForget, resendActivate, tokenLogin} from "./api"

import logo from "./static/logo.png";
import {setUserinfo} from "../redux/action";
import {connect} from "react-redux";
import {ForgetFailPopup, ForgetPopup, ResendFailPopup, ResendPopup} from "./popups";


function LoginPanel(props){
  // Ref(input的非受控组件方法)
  const emailRef = useRef('')
  const passwdRef = useRef('')

  // State
  // email输入框状态：初始化、激活、错误
  const [emailstate, setemailstate] = useState('initial')
  // 错误信息
  const [emailerror, setemailerror] = useState('')
  // email输入框状态：初始化、激活、错误
  const [passwdstate, setpasswdstate] = useState('initial')
  // 错误信息
  const [passwderror, setpasswderror] = useState('')
  // 服务器验证状态
  const [emailactivate, setemailactivate] = useState(false)
  // 是否显示密码
  const [showpasswd, setshowpasswd] = useState(false)
  
  // 密码重置
  const [reset, setreset] = useState(false)
  const [resetfail, setresetfail] = useState(false)
  const [reseterror, setreseterror] = useState('')
  
  // 重新激活
  const [resend, setresend] = useState(false)
  const [resendfail, setresendfail] = useState(false)
  const [resenderror, setresenderror] = useState('')
  
  // 显示密码
  const handleChePasswd = (e) => {
    // console.log(e.target.checked)
    setshowpasswd(e.target.checked)
  }

  // 处理点击搜索
  const handleNext = () => {
    // const pattern = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  
    const email = emailRef.current.value
    const passwd = passwdRef.current.value
    if (email === '') {
      setemailstate('error')
      setemailerror('empty')
      return
    }
    if (!pattern.test(email)) {
      setemailstate('error')
      setemailerror('invalid')
      return
    }
    if (passwd === '') {
      setpasswdstate('error')
      setpasswderror('empty')
    }
    
    // 对密码进行验证
    tokenLogin(email, passwd)
      .then((res)=>{
        // 验证失败
        if (res.state === false){
          if(res.detail === '该邮件未注册，请检查邮件拼写'){
            // 邮件未注册
            setemailstate('error')
            setemailerror('noexist')
            setpasswdstate('initial')
          } else if(res.detail === '该账号未激活'){
            // 邮件未激活
            setemailstate('error')
            setemailerror('noactivated')
            setemailactivate(true)
            setpasswdstate('initial')
          } else if(res.detail === '密码错误，请检查密码拼写'){
            // 密码输入错误
            setpasswdstate('error')
            setpasswderror('invalid')
            setemailstate('initial')
          }
        }
        // 验证成功，跳转到首页，并保存token
        else{
          console.log('登陆成功')
          // 用户保存数据
          props.setUserinfo(res)
          // 关闭登陆弹窗
          props.close()
          // 重载页面
          // eslint-disable-next-line no-restricted-globals
          location.reload(true);
        }
      })
      .catch((error)=>{
        console.log('登陆错误', error)
    })
  }


  const handleActivate = () => {
    resendActivate(emailRef.current.value)
      .then((res)=>{
        // 邮箱激活邮件发送成功
        if (res.state === true){
          setresend(true)
          setemailactivate(false)
        } else if (res.detail === '该邮件未注册，请检查邮件拼写') {
          setemailstate('error')
          setemailerror('noexist')
        } else {
          setresendfail(true)
          setresenderror(res.detail)
        }
      })
      // 失败
      .catch((error)=>{
        setresendfail(true)
        setresenderror(error.response.detail)
    })
  }

  // 输入框Enter事件响应
  const handleEnter = (e) => {
    // console.log(e.key)
    if (e.key === "Enter") {
      handleNext()
    }
  }
  
  // 忘记密码
  const handleForget = () => {
    passwdForget(emailRef.current.value)
      .then((lol)=>{
        console.log('忘记密码', lol)
        if (lol.state === true){
          setreset(true)
        }
        else{
          setresetfail(true)
          setreseterror(lol.detail)
        }
      })
      .catch((error)=>{
        setresetfail(true)
        setreseterror(error)
      })
  }

  // 输入框外点击，如果输入框为空，input样式变为initial
  const loseInputFocus = (event) => {
    try{
      if(emailRef.current.value === "" && !emailRef.current.contains(event.target)){
        setemailstate('initial')
      }
      if(passwdRef.current.value === "" && !passwdRef.current.contains(event.target)){
        setpasswdstate('initial')
      }
    } catch (error){
      // alert(error.message)
      console.log(error.message)
    }
  }
  // 事件监听
  document.addEventListener('mousedown', loseInputFocus)

  // 复杂情况下的className切换方法 //
  // className与inputstate的匹配
  const inputclassNmae = {
    initial: "loginemailinitial",
    activate: "loginemailactivate",
    error: "loginemailerror"
  }
  // className与inputstate的匹配
  const inputtitleclassNmae = {
    initial: "loginemailtitleinitial",
    activate: "loginemailtitleactivate",
    error: "loginemailtitleerror"
  }
  const emailErrorMap = {
    empty:'请输入邮件地址',
    invalid:'请输入合法的邮件地址',
    noexist:'找不到您的账号',
    noactivated:'您的账号未激活，点击下方按钮进行激活',
  }
  const passwdErrorMap = {
    empty:'请输入密码',
    invalid:'密码错误'
  }
  

  return(
    <div className="loginpaneldiv">
      <div className="loginpanelback"/>
      <div className={isMobile ? "loginmobilepanel" : "loginpanel"} >
        <i className='material-icons loginpanelclose' onClick={props.close}>close</i>
        {/*<div className='logintitle'>TechFlow</div>*/}
        <img  className='logintitleimag' src={logo} alt=''/>
        <div className='loginlogin'>登录</div>
        {/*<div className='logindes'>使用您的TechFlow账号</div>*/}
        {/*邮件*/}
        <div className='loginemialdiv'>
          <div className={inputtitleclassNmae[emailstate]}>请输入电子邮件</div>
          <input className={inputclassNmae[emailstate]} onFocus={()=>{setemailstate('activate')}} onKeyDown={handleEnter} placeholder={emailstate==='initial' ? '输入您的电子邮件' : ''} ref={emailRef}/>
        </div>
        {emailstate==='error' &&
          <div className='forgeterror'>
            <i className='material-icons loginerroricon' onClick={()=>{}}>error_outline</i>
            {emailErrorMap[emailerror]}
          </div>
        }
        {/*<div className={emailstate==='error' ? 'forgeterror' : 'forgeterrorhidden'}>*/}
        {/*  <i className='material-icons loginerroricon' onClick={()=>{}}>error_outline</i>*/}
        {/*  {emailErrorMap[emailerror]}*/}
        {/*</div>*/}
        
        {/*密码*/}
        <div className='loginemialdiv'>
          <div className={inputtitleclassNmae[passwdstate]}>请输入您的密码</div>
          <input type={showpasswd ? 'text' : 'password'} className={inputclassNmae[passwdstate]} onFocus={()=>{setpasswdstate('activate')}} onKeyDown={handleEnter} placeholder={passwdstate==='initial' ? '输入您的密码' : ''} ref={passwdRef}/>
        </div>
        {passwdstate==='error' &&
          <div className='loginerror'>
            <i className='material-icons loginerroricon' onClick={()=>{}}>error_outline</i>
            {passwdErrorMap[passwderror]}
          </div>
        }
        {/*<div className={passwdstate==='error' ? 'loginerror' : 'loginerrorhidden'}>*/}
        {/*  <i className='material-icons loginerroricon' onClick={()=>{}}>error_outline</i>*/}
        {/*  {passwdErrorMap[passwderror]}*/}
        {/*</div>*/}
        
        {/*显示密码*/}
        <div className='loginpasswddiv'>
          <input className='loginpasswdche' type='checkbox' onChange={handleChePasswd}/>
          <div className='loginpasswddes'>显示密码</div>
        </div>
        
        {/*激活*/}
        {emailactivate && <div className='loginpanelbtn' onClick={handleActivate}>激活账号</div>}
        {/*<div className={emailactivate ? 'loginactivate': 'loginactivatehidden'} onClick={handleActivate}>激活账号</div>*/}
        
        {/*登录*/}
        {!emailactivate && <div className='loginpanelbtn' onClick={handleNext}>登录</div>}
        
        {/*忘记密码/创建账号*/}
        <div className="loginpanelops">
          <Link to="/register/"><div className='logincreate' style={{display:"flex", justifyContent: "flex-start", margin:"0 auto 0 12px"}}>创建账号</div></Link>
          <div className='logincreate' style={{display:"flex", justifyContent: "flex-end", margin:"0 12px 0 auto"}} onClick={handleForget}>忘记了密码？</div>
        </div>
        
      </div>
      
      {/*显示激活邮件重发成功*/}
      {resend && <ResendPopup close={()=>{setresend(false)}}/>}
      {resendfail && <ResendFailPopup close={()=>{setresend(false)}} error={resenderror}/>}
      
      {/*显示密码重置邮件发送成功*/}
      {reset && <ForgetPopup close={()=>{setreset(false)}}/>}
      {resetfail && <ForgetFailPopup close={()=>{setresetfail(false)}} error={reseterror}/>}
    </div>

  )
}

// export default LoginPanel;

const mapStateToProps = store => ({
	user: store.app.user,
});

const mapDispatchToProps = dispatch => ({
	setUserinfo: (user) => dispatch(setUserinfo(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPanel);