
import {
    SET_INDEX_RANK,
    SET_INDEX_TOPICS,
    SET_CURRENT,
    SET_COMMENT,
    SET_PROVIDER,
    SET_TOPIC,
    SET_USER_INFO,
    SET_SEARCH,
} from './action';



import { combineReducers } from 'redux';

function appReducer(state = {}, action){
    switch(action.type){

        // 首页视频
        case SET_INDEX_RANK:
            console.log('SET_INDEX_RANK', action.rank)
            return {...state, indexRank: action.rank};
            
        case SET_INDEX_TOPICS:
            console.log('SET_INDEX_TOPICS', action.topics)
            return {...state, indexTopics: action.topics};
            
        case SET_CURRENT:
            console.log('SET_CURRENT', action.current)
            return {...state, current: action.current};
            
        case SET_COMMENT:
            console.log('SET_COMMENT', action.comments)
            return {...state, comments: action.comments};
        
        case SET_PROVIDER:
            console.log('SET_PROVIDER', state.provider)
            return {...state, provider: action.provider};
        
        case SET_TOPIC:
            console.log('SET_TOPIC', action.topic)
            return {...state, topic: action.topic}
        
        case SET_SEARCH:
            console.log('SET_SEARCH', action.searchdata)
            return {...state, search: action.searchdata};
            
        case SET_USER_INFO:
            console.log('SET_USER_INFO', action.userinfo)
            return {...state, userinfo: action.userinfo}
            
        default:
            return state;
    }
}

const reducer = combineReducers({
    app: appReducer,
});

export default reducer;