import React, {useRef, useState} from 'react'

export function SuccessPopup(props){
  return(
    <div className='registersuccessdiv'>
      <div className='registersuccess'>
        <i className='material-icons registersuccessicon' >done</i>
        <div className='registersuccesstitle'>注册成功！</div>
        <div className='registersuccesstxt'>感谢您的注册，请前往邮箱激活账号</div>
        <div className='registersuccessbtn'
             onClick={(e)=>{e.preventDefault(); props.close()}}
        >确认</div>
      </div>
    </div>
  )
}

export function SuccessPopup2(props){
  return(
    <div className='registersuccessdiv'>
      <div className='registersuccess'>
        <i className='material-icons registersuccessicon' >done</i>
        <div className='registersuccesstitle'>激活成功！</div>
        <div className='registersuccesstxt'>感谢您的注册，请点击确认登录</div>
        <div className='registersuccessbtn'
             onClick={(e)=>{e.preventDefault(); props.close()}}
        >登录</div>
      </div>
    </div>
  )
}

export function FailPopup(props){
  return(
    <div className='registersuccessdiv'>
      <div className='registersuccess'>
        <i className='material-icons registersuccessicon' >done</i>
        <div className='registersuccesstitle'>激活失败！</div>
        <div className='registersuccesstxt'>抱歉激活失败，请前往重新发送激活邮件</div>
        <div className='registersuccessbtn'
             onClick={(e)=>{e.preventDefault(); props.close()}}
        >重试</div>
      </div>
    </div>
  )
}

export function ResendPopup(props){
  return(
    <div className='registersuccessdiv'>
      <div className='registersuccess' style={{height:"200px"}}>
        <div className='registersuccesstitle'>邮件发送成功！</div>
        <div className='registersuccesstxt'>激活邮件重新发送成功，请前往邮箱重新激活</div>
        <div className='registersuccessbtn'
             onClick={(e)=>{e.preventDefault(); props.close()}}
        >确认</div>
      </div>
    </div>
  )
}

export function ResendFailPopup(props){
  return(
    <div className='registersuccessdiv'>
      <div className='registersuccess' style={{height:"200px"}}>
        <div className='registersuccesstitle'>邮件发送失败</div>
        <div className='registersuccesstxt'>激活邮件发送失败，错误信息：{props.error}，请重试</div>
        <div className='registersuccessbtn'
             onClick={(e)=>{e.preventDefault(); props.close()}}
        >确认</div>
      </div>
    </div>
  )
}


export function ForgetPopup(props){
  return(
    <div className='registersuccessdiv'>
      <div className='registersuccess' style={{height:"200px"}}>
        <div className='registersuccesstitle'>邮件发送成功！</div>
        <div className='registersuccesstxt'>密码重置邮件重新发送成功，请前往邮箱重新设置密码</div>
        <div className='registersuccessbtn'
             onClick={(e)=>{e.preventDefault(); props.close()}}
        >确认</div>
      </div>
    </div>
  )
}

export function ForgetFailPopup(props){
  return(
    <div className='registersuccessdiv'>
      <div className='registersuccess' style={{height:"200px"}}>
        <div className='registersuccesstitle'>邮件发送失败！</div>
        <div className='registersuccesstxt'>抱歉，发生错误: {props.error} 请重新尝试</div>
        <div className='registersuccessbtn'
             onClick={(e)=>{e.preventDefault(); props.close()}}
        >确认</div>
      </div>
    </div>
  )
}

export function ResetPopup(props){
  return(
    <div className='registersuccessdiv'>
      <div className='registersuccess' style={{height:"200px"}}>
        <div className='registersuccesstitle'>密码重置成功</div>
        <div className='registersuccesstxt'>密码重置完成，请牢记您的密码</div>
        <div className='registersuccessbtn'
             onClick={(e)=>{e.preventDefault(); props.close()}}
        >登录</div>
      </div>
    </div>
  )
}

export function ResetFailPopup(props){
  return(
    <div className='registersuccessdiv'>
      <div className='registersuccess' style={{height:"200px"}}>
        <div className='registersuccesstitle'>密码重置失败</div>
        <div className='registersuccesstxt'>抱歉，发生错误: {props.error}，请重新尝试密码重置</div>
        <div className='registersuccessbtn'
             onClick={(e)=>{e.preventDefault(); props.close()}}
        >重试</div>
      </div>
    </div>
  )
}

export function UnknownFailPopup(props){
  return(
    <div className='registersuccessdiv'>
      <div className='registersuccess' style={{height:"200px"}}>
        <div className='registersuccesstitle'>错误</div>
        <div className='registersuccesstxt'>抱歉，发生错误: {props.error}</div>
        <div className='registersuccessbtn' onClick={(e)=>{e.preventDefault(); props.close()}}>确认</div>
      </div>
    </div>
  )
}

