import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom';

// 资源
import star2 from "../resource/star2.png";

// 自定义组件
import {apis} from "../api";
import {calstringlength, chunk} from "../../Utiles/utiles";
import IndexItem from "./indexitem";
import {setIndexRank, setIndexTopics, setUserinfo} from "../../redux/action";
import {connect} from "react-redux";
import IndexItem2 from "../desktop/indexitem2";


function Index(props){
  // State
	const [rankname, setrankname] = useState('score')
	const [currentrank, setcurrentrank] = useState([])

 
	const switchRank = (rank) => {
		setrankname(rank)
		setcurrentrank(chunk(props.indexRank[rank], 3))
	}

	useEffect(()=>{
		
		apis('getindexrank').then(
			(res) => {
				// console.log("get mostscore response:", res);
				props.setIndexRank(res)
				setcurrentrank(chunk(res.score, 3))
			}).catch(
			(error) => {
				console.log("getindexrank request failed!", error);
			}
		);
		
		apis('getindextopics').then(
			(res) => {
				// console.log("get mostdownload response:", res);
				props.setIndexTopics(res)
			}).catch(
			(error) => {
				console.log("getindextopics request failed!", error);
			}
		);
		
	}, [])

  return (
    <div className='indexmain'>
			<div className='indexrank'>
				<div className='indexrankdiv'>
					<div className='indexranktitle'>热门排行榜</div>
					<div className='indexranktitles'>
						<div className={rankname==='score' ? 'indexranksubtitle1' : 'indexranksubtitle2'} onClick={()=>{switchRank('score')}}>最高评分</div>
						<div className={rankname==='download' ? 'indexranksubtitle1' : 'indexranksubtitle2'} onClick={()=>{switchRank('download')}}>最多下载</div>
						<div className={rankname==='recent' ? 'indexranksubtitle1' : 'indexranksubtitle2'} onClick={()=>{switchRank('recent')}}>最近更新</div>
					</div>
				</div>
				
				<div className='indexranktable'>
					{currentrank.map((group, index)=>(
						<div className='indexrankgroup' style={{width:'33.33%', minWidth:'280px'}} key={index}>
							{group.map((data, index2)=>(
								<IndexItem2 data={data} num={index*3+index2+1} index={true} key={index2}/>
								))
							}
						</div>
					))}
				</div>
				
			</div>

			<div className='indexcontent'>
				{
					Object.keys(props.indexTopics).map((topic, index)=>(
						<div style={{margin:'0 12px'}} key={index}>
				      <div className='indextitle'>
				        <div className='indextitletext'>{topic}</div>
				        <Link to={"/topic/" +topic + '/'} style={{textDecoration:"none"}}>
					        <div className='indextitlebtn'>查看更多</div>
								</Link>
				      </div>
				
				      <div className='indexitemset'>
				        {
				          props.indexTopics[topic].map((value, index)=>(
				            <IndexItem data={value} key={index}/>
				          ))}
				      </div>
				    </div>
					))
				}
			</div>

    </div>
  )
}

// export default Index;

const mapStateToProps = store => ({
	userinfo: store.app.userinfo,
	indexRank: store.app.indexRank,
	indexTopics: store.app.indexTopics
});

const mapDispatchToProps = dispatch => ({
	setUserinfo: (userinfo) => dispatch(setUserinfo(userinfo)),
	setIndexRank: (rank) => dispatch(setIndexRank(rank)),
	setIndexTopics: (topics) => dispatch(setIndexTopics(topics)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);