import { createStore, applyMiddleware } from 'redux';
import promise from 'redux-promise';
import {thunk} from 'redux-thunk';
import { composeWithDevTools } from "redux-devtools-extension";
import apiMiddleware from './middleware';

import reducer from './reducer';

const app = {
  // 首页排行榜
  indexRank: [],
  // 首页分类
  indexTopics: [],
	
  // 当前应用
  current: null,
  
  // 当前应用评论
  comments: [],
  
  // 当前分类
  topic: {},
	
  // 当前频道
  provider: {},
	
  // 用户信息
  userinfo: {
    logged: false
  },
	
  // 搜索结果
  search: {},
}

let state = {
    app,
};

// composeWithDevTools会导致移动端报错，应仅在调试时代开
// const store = createStore(reducer, state, composeWithDevTools(applyMiddleware(thunk)));
const store = createStore(reducer, state, applyMiddleware(thunk));

export default store;
