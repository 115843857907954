import axios from "axios";
import Cookies from 'js-cookie'

import {getCookieValue} from "../Utiles/utiles";
import config from '../config.json'

// const API_URL = "http://localhost:8000/auth/";
const API_URL = config.serverPath+'auth/';

// 注册：post users/
// 激活：post users/activation/
// 重新激活：post users/resend_activation/
// 获取用户信息：get users/me/
// 更新用户信息：put/patch users/me/
// 销户：delete users/me/
// 更改用户名：post users/set_username/
// 更改用户名发送邮件：post users/reset_username/
// 更改用户名邮件确认：post users/reset_username_confirm/
// 更改密码：post users/set_password/
// 更改用户名发送邮件：post users/reset_password/
// 更改用户名邮件确认：post users/reset_password_confirm/
// 登录：post users/login/
// 登出：post users/logout/
// 邮箱存在：post users/email_exist/
// 用户名存在：post users/username_exist/


export const getUserInfo = async () => {
  const token = Cookies.get('token')
  return await axios.get(
    API_URL + "users/me/",
      {
        headers:{
          "Authorization": `Token ${token}`,
          "Content-Type": "application/json"
        },
        // withCredentials:true,
      }
    )
    .then((response) => {
      return {state:true, data:response.data}
    })
    .catch((error)=>{
      console.log({state:false, detail:error.message})
      if(error.response.status === 401){
        // token过期或错误时
        if(token){
          // 删除cookie的token项
          // document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=dltheapk.com; path=/;"
          // Cookies.remove('token');
          // eslint-disable-next-line no-restricted-globals
          location.reload()
        }
      }
      return {state:false, detail:error.message}
    })
};

export const deleteUser = async () => {
  const token = getCookieValue('token')
  console.log('token', `Token ${token}`)
  return await axios.delete(
    API_URL + "users/me/",
      {
        headers:{
          "Authorization": `Token ${token}`,
          "Content-Type": "application/json"
        },
        withCredentials:true,
      }
    )
    .then((response) => {
      return {state:true, data:response.data}
    })
    .catch((error)=>{
      console.log({state:false, detail:error.message})
      return {state:false, detail:error.message}
    })
};

// 邮箱验证 //
// 存在
// {
//     "email": "chuangzhineng@outlook.com",
//     "state": "exist"
// }
// 不存在 200
// {
//     "email": "chuangzhinen1g@outlook.com",
//     "state": "not exist"
// }
// 未激活 200
// {
//     "email": "chuangzhinen1g@outlook.com",
//     "state": "not activated"
// }
export const emailVerify = async (email) => {
  const token = getCookieValue('token')
  return  await axios.post(
    API_URL + "users/email_exist/",
      {email:email},
      {
        headers:{
          "Content-Type": "application/json"
        },
        withCredentials:true,
      }
    )
    .then((response) => {
      if(response.data.state === 'exist'){
        return {state:true, data:response.data}
      } else if(response.data.state === 'not exist'){
        return {state:false, data:response.data}
      } else if(response.data.state === 'not activated'){
        return {state:false, data:response.data}
      }
    })
    .catch((error)=>{
      console.log({state:false, detail:error.message})
      return {state:false, detail:error.message}
    })
};


export const tokenLogin = async (email, password) => {
  return await axios
    .post(API_URL + "token/login/",
      {email: email, password: password, },
      {
        headers: {
          'content-type': 'application/json',
        },
        withCredentials:true
      })
    .then((response) => {
        return response.data // 登陆成功，返回token
    })
    .catch((error)=>{
      if (error.response.status === 400) {
        return error.response.data
      } else{
        return {state:false, detail: error.message}
      }
    })
};


// 用户名验证 //
// 存在 200
// {
//     "username": "chuang",
//     "state": "exist"
// }
// 不存在
// {
//     "username": "chuang",
//     "state": "not exist"
// }
export const usernameVerify = async (username) => {
  return  await axios.post(API_URL+"users/username_exist/", {username:username})
    .then((response) => {
      if(response.data.username === username){

        if(response.data.state === 'exist'){
          return {state:true, detail:'成功'}
        } else if (response.data.state === 'not exist'){
          return {state:false, detail:"用户名不一致"}
        }
      }
    })
    .catch((error)=>{
      console.log({state:false, detail:error.message})
      return {state:false, detail:error.message}
    })
};


export const register = async (username, email, password) => {
  return await axios.post(
    API_URL + "users/",
    {username: username, email: email, password: password},
    {
        headers:{
          // "Authorization": `Token ${token}`,
          "Content-Type": "application/json"
        },
        withCredentials:true,
      }
  ).then((response)=>{
    return response.data // 注册成功
  }).catch((error)=> {
    if(error.response.status === 400){
      console.log(error.response.data)
      return error.response.data
    }
    else{
      console.log({state:false, detail: error.message})
      return {state:false, detail: error.message}
    }
  })
};


export const emailActivate = async (uid, token) => {
  return await axios.post(API_URL + "users/activation/", {
    uid: uid,
    token: token,
  }).then((response)=>{
    console.log(response.data)
    if (response.status === 204){
      return {state:true, detail:"激活成功"} // 注册成功
    }
    else{
      return {state:false, detail:"激活失败"}
    }
  })
  .catch((error)=>{
    if(error.response.status === 400){
      return error.response.data
    } else {
      return {state:false, detail:`未知错误: ${error}`}
    }
  })
};


export const passwdForget = async (email) => {
  return await axios.post(API_URL + "users/reset_password/", {email: email})
    .then((response)=>{
      if (response.status === 204){
        return {state:true, detail:"已发送邮件到邮箱"};
      }
      else {
        return {state:false, detail:`未知错误: ${response.status}`};
      }
      })
    .catch((error)=>{
      if(error.response.status === 400){
        return error.response.data
      } else {
        return {state:false, detail:`未知错误: ${error.response.status}`};
      }
    })
}


export const passwdReset = async (uid, token, newpasswd) => {
  return await axios.post(
    API_URL + "users/reset_password_confirm/",
      {uid: uid, token: token, new_password: newpasswd}
    )
    .then((response)=>{
      if(response.status === 204){
        return {state:true, detail:"密码重置成功"}
      }
      else {
        return {state:false, detail:`未知错误: ${response.status}`}
      }
      })
    .catch((error)=>{
      if(error.response.status === 400){
        return error.response.data
      } else {
        return {state:false, detail:`未知错误: ${error.response.status}`}
      }
    })
}


export const resendActivate = async (email) => {
  return await axios.post(API_URL + "users/resend_activation/", {email: email})
    .then((response)=>{
      if (response.status === 204){
        return {state:true, detail:"激活邮件已发送到邮箱成功"}
      }
      else {
        return {state:false, detail:"未知错误"}
      }}
    )
    .catch((error)=>{
      if(error.response.state === 400){
        return error.response.data
      } else {
        return {state:false, detail:"未知错误"}
      }
    })
}

const AuthService = {
  emailVerify,
  tokenLogin,
  usernameVerify,
  register,
  emailActivate,
  passwdForget,
  passwdReset,
  resendActivate
}
export default AuthService;