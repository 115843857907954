import React from 'react'
import {Link} from "react-router-dom";

// 自定义组件
import star2 from "../resource/star2.png";


function IndexItem(props){

  return (
    <div className='indexitem'>
      <Link to={"/app/" + props.data.id + '/'} style={{textDecoration:"none"}} >
        <div className='indexitemdiv'>
          <img src={props.data.logooss} className='indexitemimg' alt='logo'/>
          <div className='indexitemdiv2'>
            <div className='indexitemtitle'>{props.data.name}</div>
            <div className='indexitemstar'>
              <div>{props.data.score}</div>
              <div style={{backgroundImage: `url(${star2})`, height:'12px', width:'12px', marginLeft:'6px', backgroundSize:"cover"}}/>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}


export default IndexItem;

