import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from "react-router-dom"; // react-router

// redux
import { Provider } from 'react-redux';
import store from './redux/store';

import "./index.css"


// 桌面端
import App from './myplay/app';

const root = createRoot(document.getElementById('root'))

root.render(
  <Provider store={store}>
    {/*<BrowserRouter>*/}
      <App/>
    {/*</BrowserRouter>*/}
  </Provider>
)
