import React, {useEffect, useState} from 'react'
import {useLocation, useParams} from 'react-router-dom';
import {connect} from "react-redux";

// 资源
import loadicon from "../resource/loading.png";

// 自定义组件
import IndexItem from "./indexitem"
import {apis} from "../api";
import {setSearch} from "../../redux/action";


function Search(props){
  
  // State
  const [loaded, setloaded] = useState(false)
  
  // 使用react router获取url参数
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // 通过 get 方法获取URL参数
  const keyword = searchParams.get('keyword');
  
  useEffect(()=>{
    apis('search', {}, {}, {keyword:keyword}).then(
      (res) => {
        // console.log("get search response:", res);
        props.setSearch(res)
        setloaded(true)
      }).catch(
      (error) => {
        console.log("search request failed!", error);
      }
    );

  }, [keyword])
  
  if(!loaded){
    return(
      <div style={{width:'100%', textAlign:"center"}}><img src={loadicon} className='rotateImg indexloading' style={{height:'32px', width:'32px', margin:'10px auto'}} alt=''/></div>
    )
  }

  return (

    <div className='indexitemset'>
    {
      props.search.apps.map((value, index)=>(
        <IndexItem data={value} key={index}/>))
    }
    {/*{*/}
    {/*  props.search.providers.map((value, index)=>(*/}
    {/*    <ProviderItem data={value} key={index}/>))*/}
    {/*}*/}
    </div>
  )
}


// export default Search;

const mapStateToProps = store => ({
	search: store.app.search,
});

const mapDispatchToProps = dispatch => ({
	setSearch: (searchdata) => dispatch(setSearch(searchdata))
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);