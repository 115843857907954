import React, {useRef, useState} from 'react'
import {Link, useNavigate} from "react-router-dom"; // react-router

import {emailVerify, resendActivate} from "./api"

import logo from "./static/logo.png";
import {ResendFailPopup, ResendPopup} from "./popups";


function LoginEmail(callback, deps){
  // Ref(input的非受控组件方法)
  const emailRef = useRef('')

  // State
  // input输入框状态：初始化、激活、错误
  const [inputstate, setinputstate] = useState('initial')
  // 错误信息
  const [emailerror, setemailerror] = useState('')
  // 服务器验证状态
  const [emailactivate, setemailactivate] = useState(false)
  // 显示重发激活邮件
  const [resend, setresend] = useState(false)
  const [resendfail, setresendfail] = useState(false)
  const [resenderror, setresenderror] = useState('')


  // react-route
  const navigate = useNavigate()


  // 处理点击搜索
  const handleNext = () => {
    // const pattern = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const email= emailRef.current.value
    if (email === ''){
      setinputstate('error')
      setemailerror('empty')
      return
    }
    if (!pattern.test(email)){
      setinputstate('error')
      setemailerror('invalid')
      return
    }

    // 注意useState也是异步操作，因此在自定义的异步操纵中，更新state的操作无法立即执行 //

    /*************** react函数组件异步执行axios的三种方法 ****************/
    // // method1: axios直接进行异步操作
    // const url = "http://localhost:8000/auth/users/email_exist/"
    // axios.post(url, {email:email})
    // .then((response) => {
    // //   setemailverify(response.data.email === email && response.data.state === 'exist') // state更新无法立即执行
    //   const lol = response.data.email === email && response.data.state === 'exist'
    //   if(lol){
    //     navigate("/loginpassword/")
    //   }
    //   // 验证失败
    //   if (!lol){
    //     setinputstate('error')
    //     setemailerror('noexist')
    //   }
    // })

    // method2: 外部导入axios
    emailVerify(email)
      .then((lol)=>{
      if(lol.state){
        navigate("/loginpasswd/", {state:{email: lol.data.email, username: lol.data.username}}) // 通过路由传递参数
      }
      // 验证失败
      else{
        if (lol.data.state === 'not activated'){
          setinputstate('error')
          setemailerror('noactivated')
          setemailactivate(true)
        }
        else if(lol.data.state === 'not exist'){
          setinputstate('error')
          setemailerror('noexist')
        }
        
      }
      })
      .catch((error)=>{
        console.log({'login_email error': error.message})
      })
    
    // method3: 使用state emailverify记录点击操作，用户点击后更改emailverify值，useEffect捕获更改，执行相应的函数
    // setemailverify(true)
  }
  // useEffect做用于DOM渲染之后，第一个参数是需要执行的函数，第二个参数是props或state数据（仅当这些数据改变时才会对调用第一个函数参数）,空数组则只会运行一次
  // useEffect(() => {
  //   if (emailverify !== false){
  //     const url = "http://localhost:8000/auth/users/email_exist/"
  //     axios.post(url, {email:emailRef.current.value})
  //     .then((response) => {
  //       const lol = response.data.email === emailRef.current.value && response.data.state === 'exist'
  //       if(lol){
  //         navigate("/loginpassword/")
  //       }
  //       // 验证失败
  //       if (!lol){
  //         setinputstate('error')
  //         setemailerror('noexist')
  //       }
  //     })
  //     setemailverify(false)
  //   }
  //
  // }, [emailverify])


  const handleActivate = () => {
    resendActivate(emailRef.current.value)
      .then((res)=>{
        // 邮箱激活邮件发送成功
        if (res.state === true){
          setresend(true)
        } else if (res.detail === '该邮件未注册，请检查邮件拼写') {
          setinputstate('error')
          setemailerror('noexist')
        } else {
          setresendfail(true)
          setresenderror(res.detail)
        }
      })
      // 失败
      .catch((error)=>{
        setresendfail(true)
        setresenderror(error.response.detail)
    })
  }

  // 输入框Enter事件响应
  const handleEnter = (e) => {
    // console.log(e.key)
    if (e.key === "Enter") {
      handleNext()
    }
  }

  // 输入框外点击，如果输入框为空，input样式变为initial
  const loseInputFocus = (event) => {
    try{
      if(emailRef.current.value === "" && !emailRef.current.contains(event.target)){
        setinputstate('initial')
      }
    } catch (error){
      // alert(error.message)
      console.log(error.message)
    }
  }
  // 事件监听
  document.addEventListener('mousedown', loseInputFocus)

  // 复杂情况下的className切换方法 //
  // className与inputstate的匹配
  const inputclassNmae = {
    initial: "loginemailinitial",
    activate: "loginemailactivate",
    error: "loginemailerror"
  }
  // className与inputstate的匹配
  const inputtitleclassNmae = {
    initial: "loginemailtitleinitial",
    activate: "loginemailtitleactivate",
    error: "loginemailtitleerror"
  }

  const emailErrorMap = {
    empty:'请输入邮件地址',
    invalid:'请输入合法的邮件地址',
    noexist:'找不到您的账号',
    noactivated:'您的账号未激活，点击下方按钮进行激活',
  }

  return(
    <div>
      {/*<div className='logintitle'>TechFlow</div>*/}
      <img  className='logintitleimag' src={logo} alt=''/>
      <div className='loginlogin'>登录</div>
      <div className='logindes'>使用您的TechFlow账号</div>
      <div className='loginemialdiv'>
        <div className={inputtitleclassNmae[inputstate]}>请输入电子邮件</div>
        <input className={inputclassNmae[inputstate]} onFocus={()=>{setinputstate('activate')}} onKeyDown={handleEnter} placeholder={inputstate==='initial' ? '输入您的电子邮件' : ''} ref={emailRef}/>
      </div>
      <div className={inputstate==='error' ? 'forgeterror' : 'forgeterrorhidden'}>
        <i className='material-icons loginerroricon' onClick={()=>{}}>error_outline</i>
        {emailErrorMap[emailerror]}
      </div>
      {/*<Link to="/forget/" style={{textDecoration:"none", display:'block'}}><div className='loginforget'>忘记电子邮件地址？</div></Link>*/}
      <div className={emailactivate ? 'loginactivate': 'loginactivatehidden'} onClick={handleActivate}>激活账号</div>
      <div className='loginremind'>不是您自己的计算机？请使用无痕浏览窗口进行登录。</div>
      <div>
        <Link to="/register/"><div className='logincreate'>创建账号</div></Link>
        <div className='loginnext' onClick={handleNext}>下一步</div>
      </div>
    
      {/*显示激活邮件重发成功*/}
      {resend && <ResendPopup close={()=>{setresend(false)}}/>}
      {resendfail && <ResendFailPopup close={()=>{setresend(false)}} error={resenderror}/>}
    </div>
  )
}

export default LoginEmail;