import React, {useState, useEffect} from "react"
import {Route, Routes} from "react-router-dom"
import PubSub from "pubsub-js";

// 上下文共享资源
import FunctionContext from '../context';
// 自定义组件
// 首页
import Index from "./index"
// 作者
import Provider from "./provider"
// 主题
import Topic from "./topic"
// 详情
import Item from "./item"
// 喜爱
import Favorite from "./favorite";

// 搜索
import Search from "./search"

// 容器
import Contain from "./contain"

import LoginPanel from "../../auth/login_panel";


function App(){
  
  // 提示 //
  const [tip, settip] = useState('')
  const [showtip, setshowtip] = useState(false)
	const [showlogin, setshowlogin] = useState(false)
	
	// PubSub消息订阅
  useEffect(() => {
    const token = PubSub.subscribe('login', (topic, message) => {
      console.log('消息订阅：', topic, message);
      setshowlogin(true)
    });

    return () => {
      PubSub.unsubscribe(token);
    };
  }, []);
	
	const setuptip = (message) => {
		// 左下角提示
		setshowtip(true)
		settip(message)
		// 3s后tip显示消失
		setTimeout(() => {
			setshowtip(false)
		}, 3000)
	}
	
	const operations = {
		setuptip: setuptip,
		activeLogin: ()=>{setshowlogin(true)},
	}

  return (
    <FunctionContext.Provider value={operations}>
      <div>
  
        {/* 路由 */}
        <Routes>
          <Route path="/search/" element={<Contain sub={<Search/>}/>}/>
          <Route path="/provider/:id/" element={<Contain sub={<Provider/>}/>}/>
          <Route path="/topic/:name/" element={<Contain sub={<Topic/>}/>}/>
          <Route path="/app/:id/" element={<Contain sub={<Item/>}/>}/>
          <Route path="/favorite/" element={<Contain sub={<Favorite/>}/>}/>
          
          {/*上面的路径都没有匹配到的话，重定向到首页 */}
          <Route exact path="/" element={<Contain sub={<Index/>}/>}/>
        </Routes>
        
        {/*消息提示*/}
				{showtip && <div className='tip'>{tip}</div>}
	      {/*登录弹窗*/}
        {showlogin && <LoginPanel close={()=>{setshowlogin(false)}}/>}
  
      </div>
    </FunctionContext.Provider>
  )
}


export default App;
