import React from "react";

// 自定义组件
import Header from "./header";


function Contain(props){

  return(
      <div>
        <Header/>
        <div style={{paddingTop:48}}>
          {props.sub}
        </div>
      </div>
  )
}


export default Contain;