import React, {useRef, useState} from 'react'
import {isMobile} from 'react-device-detect'; // 检测是否是移动端访问


function Contain(props){
  // Ref(input的非受控组件方法)
  const lanRef = useRef(false)
  const lanmenuRef = useRef(false)

  const [mobile, setmobile] = useState(isMobile)
  const [showlan, setshowlan] = useState(false)

  const handleClick = () => {
    setshowlan(!showlan)
  }

  // 切换中英文
  const switchlan = (lan) =>{
    if (lan === 'en'){
      alert('敬请期待')
    }

    setshowlan(false)
  }

  // 语言菜单外点击，关闭语言菜单
  const loseInputFocus = (event) => {
    try{
      if(showlan === true && !lanRef.current.contains(event.target) && !lanmenuRef.current.contains(event.target)){
        setshowlan(false)
      }
    } catch (error){
      // alert(error.message)
      console.log(error.message)
    }
  }
  // 事件监听
  document.addEventListener('mousedown', loseInputFocus)

  return(
    <div className={mobile ? 'loginmainmobile' : 'loginmain'}>
      <div className={mobile ? 'logincontainmobile': 'logincontain'}>
        {props.sub}
      </div>

      <div className='loginops' title='不可用'>
        <div className='loginlan' onClick={handleClick} ref={lanRef}>
          简体中文
          <i className='material-icons loginlandropdown' onClick={()=>{}}>arrow_drop_down</i>
        </div>
        <div className={showlan ? 'loginlanmenu' : 'loginlanmenuhidden'} ref={lanmenuRef}>
          <div className='loginlanitem' onClick={()=>{switchlan('en')}}>English</div>
          <div className='loginlanitem' onClick={()=>{switchlan('zh')}}>简体中文</div>
        </div>
        <div className='loginothers'>
          <div className='loginother'>帮助</div>
          <div className='loginother'>隐私权</div>
          <div className='loginother'>条款</div>
        </div>
      </div>

    </div>
  )
}

export default Contain;