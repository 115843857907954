import React from "react"
import {Route, Routes} from "react-router-dom"
import "./auth.css"


// 封装
import Contain from "./contain";
// 用户登录邮件
import LoginEmail from "./login_email";
// 用户登录密码
import LoginPasswd from "./login_password"
// 用户注册
import Register from "./register"
// 用户忘记密码
import Forget from "./forget";
// 用户忘记电子邮件
// 重置密码
import Reset from "./reset";

// 邮箱验证
import Jump from "./activatejump";

// 登陆面板
import LoginPanel from "./login_panel";



// 路由组件，对路径进行注册
function Auth(){

    return(
        <Routes>
          <Route exact path="/login/" element={<Contain sub={<LoginEmail/>}/>}/>
          <Route exact path="/loginpasswd/" element={<Contain sub={<LoginPasswd/>}/>}/>
          <Route exact path="/register/" element={<Contain sub={<Register/>}/>}/>
          <Route exact path="/forget/" element={<Contain sub={<Forget/>}/>}/>
          <Route exact path="/reset/" element={<Contain sub={<Reset/>}/>}/>
          <Route exact path="/jump/" element={<Jump/>}/>
          {/*<Route exact path="/panel" element={<LoginPanel/>}/>*/}
        </Routes>
    )
}


export default Auth;
