// import React from 'react'
//
//
// // 资源
// import star2 from "../static/star2.png";
// import star1 from "../static/star1.png";
//
//
// function Star(props){
//
//     const fullstar = parseInt(props.num/20)
//     const percent = (props.num%20)*5
//     const percentstar = (percent) => `${percent}%`
//
//     return (
//       <div style={{display:"inline-block",}}>
//         {
//           Array.from({length: fullstar}, (x, i) => i).map((_,i) => (
//             <div style={{display:"inline-block", backgroundImage: `url(${star2})`, height:props.size, width:props.size, textAlign:"left", position:"relative", backgroundSize:"cover", margin:'0px 1px'}} key={i}/>))
//         }
//         <div style={{display:"inline-block", backgroundImage: `url(${star1})`, height:props.size, width:props.size, textAlign:"left", position:"relative", backgroundSize:"cover", margin:'0px 1px'}}>
//           <div style={{backgroundImage: `url(${star2})`, height:props.size, width:percentstar(percent), textAlign:"left", position:"absolute", backgroundSize:"cover", }}/>
//         </div>
//         {
//           Array.from({length: 4-fullstar}, (x, i) => i).map((_,i) => (
//             <div style={{display:"inline-block", backgroundImage: `url(${star1})`, height:props.size, width:props.size, textAlign:"left", position:"relative", backgroundSize:"cover", margin:'0px 1px'}} key={i}/>
//           ))
//         }
//       </div>
//     )
// }
//
// export default Star;

import React, {useState, useImperativeHandle } from "react";
import Cookies from 'js-cookie'
import * as dateFns from "date-fns";

import star3 from "../myplay/resource/star3.png";
import star4 from "../myplay/resource/star4.png";

// 根据用户名生成一个颜色，用作用户avatar
export const stringToHslColor = (str, s, l) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl('+h+', '+s+'%, '+l+'%)';
}

export function getCookieValue(cookieName) {
  return Cookies.get(cookieName)
}

export function formatDatetime(time) {
  // return dateFns.format(time, 'yyyy-MM-dd HH:mm')
  return dateFns.format(time, 'yyyy年MM月dd日')
}

// Star组件
export const ScoreStar = function Star(props){

  const fullstar = parseInt(props.num/20)
  const percent = (props.num%20)*5
  const percentstar = (percent) => `${percent}%`

  // console.log(fullstar)
  // console.log(percent===0)
  // console.log(percentstar)
  // console.log(Array.from({length: fullstar}, (x, i) => i))
  // console.log(percentstar(percent))


  return(
    <div style={{display:"inline-block"}}>

      {Array.from({length: fullstar}, (x, i) => i).map((_,i) => (
        <div style={{display:"inline-block", backgroundImage: `url(${star4})`, height:props.size, width:props.size, textAlign:"left", position:"relative", backgroundSize:"cover", margin:'0px 1px'}} key={i}/>
      ))}

      <div style={{display: percent===0 ? 'none' : "inline-block", backgroundImage: `url(${star3})`, height:props.size, width:props.size, textAlign:"left", position:"relative", backgroundSize:"cover", margin:'0px 1px'}}>
        <div style={{backgroundImage: `url(${star4})`, height:props.size, width:percentstar(percent), textAlign:"left", position:"absolute", backgroundSize:"cover", }}/>
      </div>
      {Array.from({length: percent===0 ? 5-fullstar : 4-fullstar}, (x, i) => i).map((_,i) => (
        <div style={{display:"inline-block", backgroundImage: `url(${star3})`, height:props.size, width:props.size, textAlign:"left", position:"relative", backgroundSize:"cover", margin:'0px 1px'}} key={i}/>
      ))}
    </div>
  )
}


export const ReviewScore = function Star(props){

  // State
  const [hover, sethover] = useState(true)
  const [current, setcurrent] = useState(-1)

  // 父组件调用
  // 用useImperativeHandle暴露一些外部ref能访问的属性
  useImperativeHandle(props.onRef, () => {
    return {
      //
      value: current +1,
      // func: func,
    }
  })

  // 鼠标移出
  const DivMouseLeave = () => {
    if (hover){
      setcurrent(-1)
    }
  }

  // 鼠标移入
  const MouseEnter = (index) => {
    if (hover){
      setcurrent(index)
    }
  }

  // 鼠标点击
  const MouseClick = (index) => {
    sethover(false)
    setcurrent(index)
  }

  return(
    <div style={{display:"block"}} onMouseLeave={DivMouseLeave}>
      {Array.from({length: 5}, (x, i) => i).map((_,i) => (
        <div style={{display:"inline-block", backgroundImage: current>=i ? `url(${star4})` : `url(${star3})`, height:props.size, width:props.size, textAlign:"left", position:"relative", backgroundSize:"cover", margin:'0px 1px'}} onMouseEnter={()=>{MouseEnter(i)}} onClick={()=>{MouseClick(i)}} key={i}/>
      ))}
    </div>
  )
}


// 生成随机颜色
export const genrandomcolor = () => {
  const r = Math.floor(Math.random()*255);
  const g = Math.floor(Math.random()*255);
  const b = Math.floor(Math.random()*255);
  return 'rgba(' + r + ',' + g + ',' + b + ',0.8)'
}


// 计算字符串长度
export const calstringlength = (txt) => {
  // console.log(txt.replace(/[^\x00-\xff]/g, "01").length)
  if (txt.replace(/[^\x00-\xff]/g, "01").length > 22){
    var tmp = ''
    for(let i = 0; i < txt.length; i++){
      if(tmp.replace(/[^\x00-\xff]/g, "01").length < 20){
        tmp+=txt[i]
      }
      else {
        return tmp+"..."
      }
    }
  }
  else {
    return txt
  }

}


// 对数组进行分组
export const chunk = (arr, size) => {
  var result = [];
  for(var i = 0, len = arr.length; i < len; i+= size){
    result.push(arr.slice(i, i+ size));
  }
  return result;
}



const utiles = {
  ScoreStar,
  ReviewScore,
  genrandomcolor,
  calstringlength,
  formatDatetime,
}


export default utiles;

