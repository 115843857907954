export const SET_INDEX_RANK = 'SET_INDEX_RANK';
export const SET_INDEX_TOPICS = 'SET_INDEX_TOPICS';
export const SET_SEARCH = 'SET_SEARCH';
export const SET_CURRENT= 'SET_CURRENT';
export const SET_COMMENT= 'SET_COMMENT';
export const SET_PROVIDER = 'SET_PROVIDER';
export const SET_TOPIC = 'SET_TOPIC';
export const SET_USER_INFO = 'SET_USER_INFO';

export function setIndexRank(rank) {
    return {
        rank,
        type:SET_INDEX_RANK
    }
}

export function setIndexTopics(topics) {
    return {
        topics,
        type:SET_INDEX_TOPICS
    }
}

export function setSearch(searchdata) {
    return {
        searchdata,
        type:SET_SEARCH
    }
}

export function setCurrent(current) {
    return {
        current,
        type:SET_CURRENT
    }
}

export function setComment(comments) {
    return {
        comments,
        type:SET_COMMENT
    }
}

export function setProvider(provider) {
    return {
        provider,
        type:SET_PROVIDER
    }
}

export function setTopic(topic) {
    return {
        topic,
        type:SET_TOPIC
    }
}

export function setUserinfo(userinfo) {
    return {
        userinfo,
        type:SET_USER_INFO
    }
}
