import React, {useEffect, useState, useRef, useContext} from 'react'
import {connect} from "react-redux";

// 自定义组件
import FunctionContext from "../context";
import {formatDatetime, ScoreStar, stringToHslColor} from "../../Utiles/utiles";
import {apis} from "../api";
import {setComment} from "../../redux/action";


function Comment2(props){
  // State
  const [showmenu, setshowmenu] = useState(false)
  
  // Ref
  const menuRef = useRef(false)
  
  // context
  const functions = useContext(FunctionContext);
  
  // 当显示more菜单时，当点击位置不在more菜单内时，关闭more菜单
  const handleClickOutside = (event) => {
    if (showmenu && menuRef && !menuRef.current.contains(event.target)){
      setshowmenu(false)
      // console.log('检测到点击，并发生在more组件外')
    }
  }
  document.addEventListener('mousedown', handleClickOutside)
  useEffect(() => {
    // console.log(moreRef.current);
  }, [menuRef])


  // 提交like
  const handleLike = (status) =>{
    // 构造数据
    const likedata = {
      status: status
    }
    // post操作
    apis('updatecommentlike', {comment:props.value.id}, likedata).then(
      (res) => {
        // console.log("get commentdianzan response:", res);
        const the_comment = props.comments.filter(item => item.id === props.value.id)[0];
        the_comment.like = res.status
        const new_comments = props.comments.map(item => (item.id === props.value.id ? the_comment : item))
        props.setComment(new_comments)
      }).catch(
      (error) => {
        console.log("get response failed!", error);
      }
    );
  }
  
  const handleDelete = () => {
    // delete操作
    apis('deletecomment', {comment:props.value.id}).then(
      (res) => {
        const all_comments = props.comments
        const new_comments = all_comments.filter(item => item.id !== props.value.id);
        props.setComment(new_comments)
        setshowmenu(false)
        // functions.setuptip('已删除评论')
      },
      (error) => {
        console.log("deletecomment failed!", error);
      }
    );
  }


  return(
    <div className='comment'>
      <div className='commentuser'>
        <div className='commentavatar' style={{backgroundColor:stringToHslColor(props.value.name, 60, 60)}}>{props.value.name.slice(0,1)}</div>
        <div className='commentname'>{props.value.name}</div>
        <i className='material-icons commentmore' onClick={()=>{setshowmenu(true)}}>more_vert</i>
  
        {showmenu &&
          <div className='commentmoremenu' ref={menuRef}>
            { props.value.userid === props.userinfo.id && <div className='commentmoredelete' onClick={handleDelete}>删除</div>}
            <div className='commentmoredelete'>举报</div>
          </div>
        }
      </div>
      
      <div className='commentstar'>
        <ScoreStar num={props.value.score*20} size='13px'/>
        <div className='commenttime'>{formatDatetime(props.value.time_field)}</div>
      </div>
      
      <div className='commenttext'>{props.value.content}</div>
      
      <div className='commentlike'>
        <div className='commentlike1'>您认为这条评价有帮助吗？</div>
        <div className={props.value.like===1 ? 'commentlike3' : 'commentlike2'} onClick={()=>{handleLike(1)}}>是</div>
        <div className={props.value.like===0 ? 'commentlike3' : 'commentlike2'} onClick={()=>{handleLike(0)}}>否</div>
      </div>
    </div>
  )
}


// export default Comment2;

const mapStateToProps = store => ({
  userinfo: store.app.userinfo,
  comments: store.app.comments,
});

const mapDispatchToProps = dispatch => ({
  setComment: (comments) => dispatch(setComment(comments)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Comment2);


