import axios from "axios";
import Cookies from 'js-cookie'
import PubSub from "pubsub-js";

import config from '../config.json'

const API_URL = config.serverPath+'myplay/';

// 首页排名：get main/
// 首页分类：get topics/
// 分类详情：get topic/<str:name>/
// 开发商详情：get provider/<int:providerid>/
// 应用详情：get app/<int:appid>/ (logged)
// 用户收藏列表：get favorite/
// 用户创建收藏：post favorite/<int:appid>/
// 用户删除收藏：delete favorite/<int:appid>/
// 应用评论：get comment/<int:appid>/ (logged)
// 创建评论：post commentadd/<int:appid>/
// 删除评论：delete commentdelete/<int:commentid>/
// 创建评论喜欢：post commentlike/<int:commentid>/     // 由put实现
// 更新评论喜欢：put commentlike/<int:commentid>/
// 删除评论喜欢：delete commentlike/<int:commentid>/   // 由put实现
// 应用举报列表：get report/
// 创建应用举报：post report/<int:appid>/
// 请求列表：get request/
// 创建请求：post request/
// 搜索：get search?keyword={}

const api_list = {
  // 搜索：get search?keyword={}
  search:{
    method: 'get',
    url: 'search',
    auth: false,
  },
  // 主题列表： get topiclist/
  gettopiclist:{
    method: 'get',
    url: 'topiclist',
    auth: false,
  },
  // 首页排名：get main/
  getindexrank:{
    method: 'get',
    url: 'main/',
    auth: false,
  },
  // 首页分类：get topics/
  getindextopics:{
    method: 'get',
    url: 'topics/',
    auth: false,
  },
  // 分类详情：get topic/<str:name>/
  gettopic:{
    method: 'get',
    url: 'topic/',
    auth: false,
  },
  // 开发商详情：get provider/<int:providerid>/
  getprovider:{
    method: 'get',
    url: 'provider/',
    auth: false,
  },
  // 应用详情：get app/<int:appid>/ (logged)
  getapp:{
    method: 'get',
    url: 'app/',
    auth: false,
  },
  // 用户关注列表：get favorite/
  getfavorite:{
    method: 'get',
    url: 'favorite/',
    auth: true,
  },
  // 用户创建收藏：post favorite/<int:appid>/
  postfavorite:{
    method: 'post',
    url: 'favorite/',
    auth: true,
  },
  // 用户取消关注：delete favorite/<int:appid>/
  deletefavorite:{
    method: 'delete',
    url: 'favorite/',
    auth: true,
  },
  // 应用评论：get comment/<int:appid>/ (logged)
  getcomments:{
    method: 'get',
    url: 'comment/',
    auth: false,
  },
  getusercomments:{
    method: 'get',
    url: 'usercomment/',
    auth: true,
  },
  // 创建评论：post commentadd/<int:appid>/
  postcomment:{
    method: 'post',
    url: 'commentadd/',
    auth: true,
  },
  // 删除评论：delete commentdelete/<int:commentid>/
  deletecomment:{
    method: 'delete',
    url: 'commentdelete/',
    auth: true,
  },
  // 创建评论喜欢：post commentlike/<int:commentid>/     // 由put实现
  // 更新评论喜欢：put commentlike/<int:commentid>/
  // 删除评论喜欢：delete commentlike/<int:commentid>/   // 由put实现
  updatecommentlike:{
    method: 'put',
    url: 'commentlike/',
    auth: true,
  },
  // 应用举报列表：get report/
  getreport:{
    method: 'get',
    url: 'report/',
    auth: true,
  },
  // 创建应用举报：post report/<int:appid>/
  postreport:{
    method: 'post',
    url: 'report/',
    auth: true,
  },
  // 请求列表：get request/
  getrequest:{
    method: 'get',
    url: 'request/',
    auth: true,
  },
  // 提交请求：post request/
  postrequest:{
    method:'post',
    url:'request/',
    auth:true,
  },
  // 删除请求：delete request/
  deleterequest:{
    method:'delete',
    url:'request/',
    auth:true,
  }
}


export const apis = (name, routeparams={},  body={}, urlparams={},) => {
  console.log(name, routeparams, body)
  const api = api_list[name]
  console.log(api)
  const instance = axios.create()
  const token = Cookies.get('token')
  console.log('是否需要登录：', api.auth && !token)
  if(api.auth && !token){
    // 提醒用户登录
    return new Promise((resolve, reject) => {
    // 提醒用户登录
    PubSub.publish('login', api);
    reject(new Error('请登录后操作'));
  });
  }
  // 路由参数
  let routeparams_ = ''
  if(Object.keys(routeparams).length !== 0){
    routeparams_ = Object.values(routeparams).join('/') + '/';
  }
  // URL参数
  let urlparams_ = ''
  if(Object.keys(urlparams).length !== 0){
    urlparams_ = '/?'
    for (let key in urlparams) {
      urlparams_ += `${key}=${urlparams[key]}&`
    }
    urlparams_ = urlparams_.slice(0, -1)
  }
  let headers = {
      'Content-Type': 'application/json',
      "Authorization": `Token ${token}`,
  }
  if(api.auth === false && !token){
    headers = {'Content-Type': 'application/json'}
  }
  let config = {
      method: api.method,
      headers: headers,
      url: `${API_URL}${api.url}${routeparams_}${urlparams_}`,
      withCredentials: true
  }
  if(api.method === 'post' || api.method === 'put'){
      config['data'] = body
  }
  return instance(config).then((res) => {
    console.log('**********************')
    return res.data
    }
  ).catch((error)=>{
    console.log('请求错误', api, routeparams, body, headers, error)
    if(error.response.status === 401){
      // token过期或错误时
      if(token){
        // 删除cookie的token项
        // document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=dltheapk.com; path=/;"
        // Cookies.remove('token');
        // eslint-disable-next-line no-restricted-globals
        location.reload()
      }
    }

    // return Promise.reject(error);
    throw error
  })
}

