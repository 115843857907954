import React, {useRef, useState} from 'react'
import {useLocation, useNavigate} from "react-router-dom";

import icon from "./static/icon.jpg";
import logo from "./static/logo.png";

import {tokenLogin, passwdForget} from "./api";
import {setUserinfo} from "../redux/action";
import {connect} from "react-redux";
import {stringToHslColor} from "../Utiles/utiles";
import {ForgetFailPopup, ForgetPopup} from "./popups";


function LoginPasswd(props){
  // State
  // 显示密码充值邮件发送弹窗
  const [reset, setreset] = useState(false)
  const [resetfail, setresetfail] = useState(false)
  const [reseterror, setreseterror] = useState('')
  
  // Ref(input的非受控组件方法)
  const passwdRef = useRef('')

  // react-route
  const navigate = useNavigate()
  const routerparams = useLocation() // navigate传递的参数
  
  // const useremail = 'useremail'
  // const username = 'username'
  // login_email会发送一个路由参数到login_password，如果没有参数直接访问login_password会跳转回login_email
  let useremail = '';
  let username = '';
  try{
    useremail = routerparams.state.email
    username = routerparams.state.username
  }catch (error){
    console.log('查找不到路由参数')
    // navigate("/login/")
    // alert(window.location.href)         // http://localhost:3000/loginpasswd/
    // alert(window.location.hostname)     // localhost
    // alert(window.location.host)         // localhost:3000
    // alert(window.location.origin)       // http://localhost:3000
    window.location.replace(window.location.origin + '/login/');
    console.log({'login_password error': error.message})
  }


  // State
  // input输入框状态：初始化、激活、错误
  const [inputstate, setinputstate] = useState('initial')
  const [passwderror, setpasswderror] = useState('')
  // 是否显示密码
  const [passwdstate, setpasswdstate] = useState(false)

  // 显示密码
  const handleChePasswd = (e) => {
    // console.log(e.target.checked)
    setpasswdstate(e.target.checked)
  }

  // 处理点击搜索
  const handleNext = () => {
    if (passwdRef.current.value === '') {
      setinputstate('error')
      setpasswderror('empty')
    }
    else{
      // 对密码进行验证
      tokenLogin(useremail, passwdRef.current.value)
        .then((res)=>{
          // 验证失败
          if (res.state === false){
            setinputstate('error')
            setpasswderror('invalid')
          }
          // 验证成功，跳转到首页，并保存用户数据
          else{
            props.setUserinfo(res)
            navigate("/")
          }
        })
        .catch((error)=>{
          console.log(error)
      })

    }
  }

  // 输入框Enter事件响应
  const handleEnter = (e) => {
    // console.log(e.key)
    if (e.key === "Enter") {
      handleNext()
    }
  }

  // 忘记密码
  const handleForget = () => {
    passwdForget(useremail)
      .then((lol)=>{
        if (lol.state === true){
          setreset(true)
        }
        else{
          setresetfail(true)
          setreseterror(lol.detail)
        }
      })
      .catch((error)=>{
        setresetfail(true)
        setreseterror(error)
      })
  }


  // 输入框外点击，如果输入框为空，input样式变为initial
  const loseInputFocus = (event) => {
    try{
      if(passwdRef.current.value === "" && !passwdRef.current.contains(event.target)){
        setinputstate('initial')
      }
    } catch (error){
      console.log(error)
    }
  }
  // 事件监听
  document.addEventListener('mousedown', loseInputFocus)

  // 复杂情况下的className切换方法 //
  // className与inputstate的匹配
  const inputclassNmae = {
    initial: "loginemailinitial",
    activate: "loginemailactivate",
    error: "loginemailerror"
  }
  // className与inputstate的匹配
  const inputtitleclassNmae = {
    initial: "loginemailtitleinitial",
    activate: "loginemailtitleactivate",
    error: "loginemailtitleerror"
  }

  const passwdErrorMap = {
    empty:'请输入密码',
    invalid:'密码错误'
  }
  
  const avatarColor = stringToHslColor(username, 60, 60)


  return(
    <div>
      {/*<div className='logintitle'>TechFlow</div>*/}
      <img  className='logintitleimag' src={logo} alt=''/>
      <div className='loginlogin'>{username}</div>

      <div className='loginuserdiv'>
        <div className='loginuseremail'>
          {/*<img  className='loginuseravatar' src={icon} alt=''/>*/}
          <div className='loginuseremailicon' style={{background:avatarColor}}> {username.slice(0,1)} </div>
          {useremail}
        </div>
      </div>

      <div className='loginemialdiv'>
        <div className={inputtitleclassNmae[inputstate]}>请输入您的密码</div>
        <input type={passwdstate ? 'text' : 'password'} className={inputclassNmae[inputstate]} onFocus={()=>{setinputstate('activate')}} onKeyDown={handleEnter} placeholder={inputstate==='initial' ? '输入您的密码' : ''} ref={passwdRef}/>
      </div>

      <div className={inputstate==='error' ? 'loginerror' : 'loginerrorhidden'}>
        <i className='material-icons loginerroricon' onClick={()=>{}}>error_outline</i>
        {passwdErrorMap[passwderror]}
      </div>

      <div className='loginpasswddiv'>
        <input className='loginpasswdche' type='checkbox' onChange={handleChePasswd}/>
        <div className='loginpasswddes'>显示密码</div>
      </div>

      <div>
        {/*点击忘记密码，向服务器发送重置密码请求，服务器向邮件发送验证，用户点击邮件的验证链接跳转到密码重置页面*/}
        {/*<Link to="/forget/"><div className='logincreate'>忘记了密码？</div></Link>*/}
        <div className='logincreate' onClick={handleForget}>忘记了密码？</div>
        <div className='loginnext' onClick={handleNext}>下一步</div>
      </div>
  
      {/*显示密码重置邮件发送成功*/}
      {reset && <ForgetPopup close={()=>{setreset(false)}}/>}
      {resetfail && <ForgetFailPopup close={()=>{setreset(false)}} error={reseterror}/>}
    </div>
  )
}

// export default LoginPasswd;

const mapStateToProps = store => ({
	user: store.video.user,
});

const mapDispatchToProps = dispatch => ({
	setUserinfo: (user) => dispatch(setUserinfo(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPasswd);