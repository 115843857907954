// 暂时使用邮箱验证链接的方式，对账号进行验证，不使用验证码
import React, {useRef, useState} from 'react'
import {Link, useNavigate} from "react-router-dom";

import icon from "./static/icon.jpg";
import logo from "./static/logo.png";


function Forget(){
  // Ref(input的非受控组件方法)
  const codeRef = useRef('')

  // State
  // input输入框状态：初始化、激活、错误
  const [inputstate, setinputstate] = useState('initial')
  const [codeerror, setcodeerror] = useState('')

  // react-route
  const navigate = useNavigate()


  // 处理点击下一步
  const handleNext = () => {

    if (codeRef.current.value === ''){
      setinputstate('error')
      setcodeerror('empty')}
    else{
      // 对验证码进行验证
      const verified = true
      // 验证成功：跳转reset
      if(verified){
        navigate("/index/")
      }
      // 验证失败
      if (!verified){
        setinputstate('error')
      }

    }
  }


  // 输入框外点击，如果输入框为空，input样式变为initial
  const loseInputFocus = (event) => {
    try{
      if(codeRef.current.value === "" && !codeRef.current.contains(event.target)){
        setinputstate('initial')
      }
    } catch (error){
      console.log(error.message)
    }
  }
  // 事件监听
  document.addEventListener('mousedown', loseInputFocus)

  // 复杂情况下的className切换方法 //
  // className与inputstate的匹配
  const inputclassNmae = {
    initial: "loginemailinitial",
    activate: "loginemailactivate",
    error: "loginemailerror"
  }
  // className与inputstate的匹配
  const inputtitleclassNmae = {
    initial: "loginemailtitleinitial",
    activate: "loginemailtitleactivate",
    error: "loginemailtitleerror"
  }

  const codeErrorMap = {
    empty:'请输入验证码',
    invalid:'验证码错误'
  }

  return(
    <div>
      {/*<div className='logintitle'>TechFlow</div>*/}
      <img  className='logintitleimag' src={logo} alt=''/>
      <div className='loginlogin'>账号恢复</div>
      <div className='forgettip'>为了保护您的帐号安全，TechFlow 希望确认是您本人在尝试登录</div>

      <div className='loginuserdiv'>
        <div className='loginuseremail'>
          <img  className='loginuseravatar' src={icon} alt=''/>
          chuangzha555555555nji@gmail.com
        </div>
      </div>

      <div className='forgetcodetip'>验证码刚刚已通过电子邮件发送到了 chuangzhineng@outlook.com</div>

      <div className='loginemialdiv'>
        <div className={inputtitleclassNmae[inputstate]}>输入验证码</div>
        <input className={inputclassNmae[inputstate]} onFocus={()=>{setinputstate('activate')}} placeholder={inputstate==='initial' ? '输入验证码' : ''} ref={codeRef}></input>
      </div>

      <div className={inputstate==='error' ? 'forgeterror' : 'forgeterrorhidden'}>
        <i className='material-icons loginerroricon' onClick={()=>{}}>error_outline</i>
        {codeErrorMap[codeerror]}
      </div>

      <div className='forgetopsdiv'>
        <Link to="/forget/"><div className='logincreate'>忘记了密码？</div></Link>
        <div className='loginnext' onClick={handleNext}>下一步</div>
      </div>
    </div>
  )
}

export default Forget;