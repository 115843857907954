import React, {useRef, useState} from 'react'
import {Link, useNavigate} from "react-router-dom";

import {passwdReset} from "./api";
import icon from "./static/icon.jpg";
import logo from "./static/logo.png";
import {ResetFailPopup, ResetPopup} from "./popups";


function Reset(){
  // Ref(input的非受控组件方法)
  const passwdRef = useRef('')
  const passwd2Ref = useRef('')

  // State
  // input输入框状态：初始化、激活、错误
  const [passwdstate, setpasswdstate] = useState('initial')
  const [passwd2state, setpasswd2state] = useState('initial')
  const [passwderror, setpasswderror] = useState('')
  // 显示密码
  const [showpasswd, setshowpasswd] = useState(false)
  
  // reset
  const [resetsuccess, setresetsuccess] = useState(false)
  const [resetfail, setresetfail] = useState(false)
  // 错误信息
  const [error, seterror] = useState('')

  // react-route
  const navigate = useNavigate()

  // 显示密码
  const handleChePasswd = (e) => {
    // console.log(e.target.checked)
    setshowpasswd(e.target.checked)
  }


  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);

  // 处理点击下一步
  const handleNext = () => {
    const passwd = passwdRef.current.value
    const passwd2 = passwd2Ref.current.value
    const pattern = /[a-zA-Z0-9-]$/
    // 密码为空
    if (passwd === ''){
      setpasswdstate('error')
      setpasswd2state('error')
      setpasswderror('empty')
      return
    }
    // 密码匹配
    if (passwd !== passwd2){
      setpasswdstate('error')
      setpasswd2state('error')
      setpasswderror('unmatched')
      return
    }
    // 密码长度
    if (8>passwd.length || passwd.length>20){
      setpasswdstate('error')
      setpasswd2state('error')
      setpasswderror('invalid1')
      return
    }
    // 密码字符
    if (!pattern.test(passwd)){
      setpasswdstate('error')
      setpasswd2state('error')
      setpasswderror('invalid2')
      return
    }
    // 本地验证成功

    // 提交服务器，进行服务器验证
    passwdReset(params.get('uid'), params.get('token'), passwd)
      .then((lol)=>{
        if (lol.state === true){
          setresetsuccess(true)
        }
        else{
          setresetfail(true)
          seterror(lol.detail)
        }
      })
      .catch((error)=>{
        setresetfail(true)
        seterror(error)
      })

  }


  // 输入框外点击，如果输入框为空，input样式变为initial
  const loseInputFocus = (event) => {
    try{
      if(passwdRef.current.value === "" && !passwdRef.current.contains(event.target)){
        setpasswdstate('initial')
        setpasswderror('')
      }
      if(passwd2Ref.current.value === "" && !passwd2Ref.current.contains(event.target)){
        setpasswd2state('initial')
        setpasswderror('')
      }
    } catch (error){
      console.log(error)
    }

  }
  // 事件监听
  document.addEventListener('mousedown', loseInputFocus)

  // 复杂情况下的className切换方法 //
  // className与inputstate的匹配
  const inputclassNmae = {
    initial: "loginemailinitial",
    activate: "loginemailactivate",
    error: "loginemailerror"
  }
  // className与inputstate的匹配
  const inputtitleclassNmae = {
    initial: "loginemailtitleinitial",
    activate: "loginemailtitleactivate",
    error: "loginemailtitleerror"
  }

  const passwdErrorMap = {
    empty:'请输入密码',
    unmatched:'两次输入密码不一致',
    invalid1:'请输入合法密码（长度要求8-20位）',
    invalid2:'请输入合法密码（字符要求英文字母大小写、数字、下划线）',
  }

  return(
    <div>
      {/*<div className='logintitle'>Google</div>*/}
      <img  className='logintitleimag' src={logo} alt=''/>
      <div className='loginlogin'>更改密码</div>

      <div className='loginuserdiv'>
        <div className='loginuseremail'>
          {/*<img  className='loginuseravatar' src={icon} alt=''/>*/}
          {params.get('email')}
        </div>
      </div>

      <div className='resetpasswdtip'>创建一个安全系数高的密码</div>

      <div className='loginemialdiv'>
        <div className={inputtitleclassNmae[passwdstate]}>设置密码</div>
        <input type={showpasswd ? 'text' : 'password'} className={inputclassNmae[passwdstate]} onFocus={()=>{setpasswdstate('activate');setpasswderror('')}} placeholder={passwdstate==='initial' ? '设置密码' : ''} ref={passwdRef}></input>
      </div>
      <div className='loginemialdiv'>
        <div className={inputtitleclassNmae[passwd2state]}>确认</div>
        <input type={showpasswd ? 'text' : 'password'} className={inputclassNmae[passwd2state]} onFocus={()=>{setpasswd2state('activate');setpasswderror('')}} placeholder={passwd2state==='initial' ? '确认' : ''} ref={passwd2Ref}></input>
      </div>

      <div className='registertip'>长度要求8-20位， 字符要求英文字母大小写、数字、下划线</div>


      <div className={passwderror!=='' ? 'loginerror' : 'loginerrorhidden'}>
        <i className='material-icons loginerroricon' onClick={()=>{}}>error_outline</i>
        {passwdErrorMap[passwderror]}
      </div>

      <div className='loginpasswddiv'>
        <input className='loginpasswdche' type='checkbox' onChange={handleChePasswd}/>
        <div className='loginpasswddes'>显示密码</div>
      </div>

      <div>
        <Link to="/login/"><div className='logincreate'>返回登陆</div></Link>
        <div className='loginnext' onClick={handleNext}>下一步</div>
      </div>
  
      
      {resetsuccess && <ResetPopup close={()=>{navigate('/login/')}}/>}
      {resetfail && <ResetFailPopup close={()=>{setresetfail(false)}} error={error}/>}
    </div>
  )
}

export default Reset;