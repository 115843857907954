import React, {useEffect, useState} from 'react'
import {useNavigate} from "react-router-dom"; // react-router

import {emailActivate} from "./api"
import {FailPopup, SuccessPopup2} from "./popups";

// 邮箱激活链接
function ActivateJump(){
  // State
  const [success, setsuccess] = useState(false)
  const [fail, setfail] = useState(false)
  const [error, seterror] = useState('')

  // react-route
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const navigate = useNavigate()

  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(()=>{
    console.log(params.get('uid'))
    console.log(params.get('token'))
    emailActivate(params.get('uid'), params.get('token'))
      .then((res)=>{
        if (res.state === true){
          // 激活成功，跳转到登录页面
          setsuccess(true)
        }
        else{
          // 激活失败，跳转到注册页面
          setfail(false)
          seterror(res.detail)
        }
      }
    ).catch((error)=>{
      setfail(false)
      seterror(error.response.detail)
    })
  }, [])
  
  const popupNavigate = () => {
    navigate("/login/")
  }


  return(
    <div style={{width:'100vw', height:'100vh'}}>
      <div style={{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        fontSize:"16px",
        fontWeight: 600,
        height:'50vh'
      }}>正在激活。。。</div>
  
      {success && <SuccessPopup2 close={popupNavigate}/>}
      {fail && <FailPopup close={popupNavigate} error={error}/>}
    </div>

  )
}


export default ActivateJump;