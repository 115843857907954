import React, {useEffect, useState, useRef} from 'react'
import {connect} from "react-redux";

// 自定义组件
import {ReviewScore} from "../../Utiles/utiles";
import {apis} from "../api";
import {setComment} from "../../redux/action";


function Comment1(props){
	
	// State
	// 输入框是否激活
	const [activate, setactivate] = useState(false)
	// 是否写东西了
	const [wrote, setwrote] = useState(false)
	
	// 评论输入框
	const commentRef = useRef()
	// 子组件对象
	const ChildRef = React.createRef()
	
	// 提交评论
	const submitComment = () => {
		if(commentRef.current.value !== ''){
			// 构造数据
			const commentdata = {
				score: ChildRef.current.value,
				content: commentRef.current.value,
			}
			console.log('评论内容：', commentdata)
			
			// 提交到数据库
			apis('postcomment', {comment: props.id}, commentdata).then(
				(res) => {
					// alert("提交评论成功")
					// 将评论添加到评论列表第一项
					const old_comments = props.comments
					console.log('old_comments', old_comments)
					old_comments.unshift(res)
					console.log('new_comments', old_comments)
					props.setComment(old_comments)
					// 状态重置
					commentRef.current.value = ''
					setwrote(false)
					setactivate(false)
				}).catch(
				(error) => {
					console.log("postcomment failed!", error);
				});
		}
	}
	
	// 激活输入框
	const inputChanged =() => {
		if (!props.userinfo.logged){
			alert('请登录')
			return
		}
		// window.console.log(commentRef.current.value === '')
		if (commentRef.current.value !== ''){
			setwrote(true)
		}
		else {setwrote(false)}
	}
	
	// 点击取消
	const cancelClick = () => {
		setactivate(false)
		commentRef.current.value = null
	}
	
	return(
		<div className='writecommentdiv'>
			<div className='writecommentavatar' style={{backgroundColor:props.color}}>{props.name.slice(0,1)}</div>
			
			<div className='writecommentmain'>
				
				<ReviewScore size={16} onRef={ChildRef}/>
				
				<input className={activate ? 'writecommentinputactivate' : 'writecommentinput'} placeholder='添加评论...' onFocus={()=>{setactivate(true)}} onChange={inputChanged} ref={commentRef}/>
				{activate &&
					<div className='writecommentops'>
						<div className='writecommentcancel' onClick={cancelClick}>取消</div>
						<div className={wrote ? 'writecommentsubmitactivate' : 'writecommentsubmit'} onClick={submitComment}>评论</div>
					</div>
				}
			</div>
		</div>
	
	)
}


// export default Comment;

const mapStateToProps = store => ({
	userinfo: store.app.userinfo,
	comments: store.app.comments,
});

const mapDispatchToProps = dispatch => ({
	setComment: (comments) => dispatch(setComment(comments)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Comment1);