import React from 'react'
import {Link} from "react-router-dom";

// 自定义组件
import star2 from "../resource/star2.png";


function IndexItem2(props){

  return (
		<div className='indexitem2wrapper'>
			<Link to={"/app/" + props.data.id} style={{textDecoration: "none"}}>
				<div className='indexitem2'>
					{props.index && <div className='indexitem2num'>{props.num}</div>}
					<img className='indexitem2icon' src={props.data.logooss} alt=''/>
					<div className='indexitem2info'>
						<div className='indexitem2name'>{props.data.name}</div>
						<div className='indexitem2category'>{props.data.category}</div>
		        <div className='indexitem2star'>
		          <div style={{cursor:"pointer"}}>{props.data.score}</div>
		          <div style={{backgroundImage: `url(${star2})`, height:'12px', width:'12px', marginLeft:'6px', backgroundSize:"cover", cursor:"pointer"}}/>
		        </div>
					</div>
				</div>
			</Link>
		</div>

  )
}


export default IndexItem2;

