import React, {useEffect, useState} from 'react'
import {useParams} from "react-router-dom";
import {connect} from "react-redux";

// 资源
import loadicon from "../resource/loading.png";

// 自定义组件
import {apis} from "../api";
import IndexItem from "./indexitem";
import {setProvider} from "../../redux/action";


function Provider(props){
	
	// State
	const [loaded, setloaded] = useState(false)
	
	// Route
	const paras = useParams()
	const providerid = paras.id
	
	useEffect(()=>{
		// 应用详情
		apis('getprovider', {provider:providerid}).then(
			(res) => {
				// console.log("get favoritecheck response:", res);
				props.setProvider(res)
				setloaded(true)
			}).catch(
			(error) => {
				console.log("getprovider request failed!", error);
			}
		);
	}, [providerid])
	
	if(!loaded){
    return(
      <div style={{width:'100%', textAlign:"center"}}><img src={loadicon} className='rotateImg indexloading' style={{height:'32px', width:'32px', margin:'10px auto'}} alt=''/></div>
    )
  }
	
	
	return(
		<div className='creatorcontent'>
			{props.provider.logooss &&
				<div>
					<img src={props.provider.backgroundoss} className='creatorback' alt=''/>
				  <div className='creatoruser'>
					  <div className='creatoruserdiv'>
						  <img src={props.provider.logooss} className='creatoruseravatar' alt=''/>
							<div className='creatorusername'>{props.provider.name}</div>
					  </div>
					  <div className='creatoruserdes'>{props.provider.description}</div>
				  </div>
					
					{/*/!*CreatorItem*!/*/}
				  {/*<div className='creatorgood'>*/}
				  {/*  <img src={props.provider.logooss} className='creatorgoodimg' alt=''/>*/}
				  {/*  <div className='creatorgooddiv'>*/}
				  {/*    <div className='creatorgood1'>*/}
				  {/*      <img src={props.provider.logooss} className='creatorgoodlogo' alt=''/>*/}
				  {/*      <div className='creatorgood2'>*/}
				  {/*        <div className='creatorgoodname'>名称名称名称名称名称名称名称名称名称名称名称名称名称名称名称名称名称名称名称名称名称</div>*/}
				  {/*        <div className='creatorgoodscore'>评分</div>*/}
				  {/*      </div>*/}
				  {/*    </div>*/}
				  {/*    <div className='creatorgooddes'>描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述描述</div>*/}
				  {/*  </div>*/}
				  {/*</div>*/}
					
				</div>
			}
			
		  <div className='creatortitle2'>{props.provider.name}的产品</div>
		  <div className='indexitemset'>
		    {
		      props.provider.appprovider.map((value, index)=>(
		        <IndexItem data={value} key={index}/>
		      ))}
		  </div>
			
		  {/*<div className='creatortitle2'>其他信息</div>*/}
		  {/*<div className='creatorothers'>*/}
		  {/*  <div className='creatoritem'>*/}
		  {/*    <div className='creatoritemname'>报告</div>*/}
		  {/*    <div className='creatoritemcontent'>举报不当内容</div>*/}
		  {/*  </div>*/}
		  {/*</div>*/}
		</div>
	)
}


// export default Provider;

const mapStateToProps = store => ({
	provider: store.app.provider,
});

const mapDispatchToProps = dispatch => ({
	setProvider: (provider) => dispatch(setProvider(provider))
});

export default connect(mapStateToProps, mapDispatchToProps)(Provider);