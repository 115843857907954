import React, {useEffect, useState} from 'react'
import {useParams} from "react-router-dom";
import {connect} from "react-redux";

// 资源
import loadicon from "../resource/loading.png";

// 自定义组件
import IndexItem from "./indexitem"
import {apis} from "../api";
import {setTopic} from "../../redux/action";


function Topic(props){

  // State
  const [loaded, setloaded] = useState(false)

  // 使用react router获取路由参数
  const paras = useParams()
  const topicname = paras.name

  useEffect(()=>{
    apis('gettopic', {name:topicname}).then(
      (res) => {
        // console.log("get topic response:", res);
        props.setTopic(res)
        setloaded(true)
      }).catch(
      (error) => {
        console.log("gettopic request failed!", error);
      }
    );
  }, [topicname])
  
  if(!loaded){
    return(
      <div style={{width:'100%', textAlign:"center"}}><img src={loadicon} className='rotateImg indexloading' style={{height:'32px', width:'32px', margin:'10px auto'}} alt=''/></div>
    )
  }
  
  return (
    <div className='contain'>
      <div style={{overflow:"auto", margin:'10px'}}>
        <div style={{fontSize:'20px', fontWeight:"bold", lineHeight:'32px', color:'#3d3d3d', float:"left"}}>{topicname}</div>
      </div>

	    <div className='indexitemset'>
        {
          props.topic.map((value, index)=>(
            <IndexItem data={value} key={index}/>
          ))}
      </div>

    </div>
  )
}

// export default Topic;

const mapStateToProps = store => ({
	topic: store.app.topic
});

const mapDispatchToProps = dispatch => ({
	setTopic: (topic) => dispatch(setTopic(topic)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Topic);